import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie'
import { api } from '../utils/functions';
import { useNavigate, NavLink } from 'react-router-dom';
import { Nav, NavButton } from '../components/Nav';
const Profile = () => {
    const [cookie, , removeCookie] = useCookies(['session-token'])
    const [mount, setMount] = useState(false)
    const [selected, setSelected] = useState("1")
    const [roles, setRoles] = useState([])
    const [histories, setHistories] = useState([])
    const [scores, setScores] = useState([])
    const [favorites, setFavorites] = useState([])
    const [data, setData] = useState({})

    const charToData = {
        "e" : ["nbEp","Épisodes"],
        "f" : ["nbFl","Films"],
        "o" : ["nbOa","OAVs"]
    }

    let navigate = useNavigate()
    const [id, setId] = useState()
    useEffect(() => {
        if (!mount) {
            setMount(true)
            if (cookie['session-token'])
                axios.post(`${api}/users/check`, { token: cookie['session-token'] })
                    .then(res => {
                        if (!res.data.correct) {
                            navigate('/login', { replace: true })
                            removeCookie('session-token')
                        }
                        else {
                            setId(res.data.correct.id)
                        }
                    })
            else
                navigate('/login', { replace: true })
            
            axios.get(`${api}/histories/list`,{headers: {Autorization: cookie['session-token']}})
                .then(res => {
                    setHistories(res.data.histories)
                })
            axios.get(`${api}/favorites/list`,{headers: {Autorization: cookie['session-token']}})
                .then(res => {
                    setFavorites(res.data.favorites)
                })
            axios.get(`${api}/scores/list`,{headers: {Autorization: cookie['session-token']}})
                .then(res => {
                    setScores(res.data.scores)
                })
            axios.get(`${api}/users/info`,{headers: {Autorization: cookie['session-token']}})
                .then(res => {
                    setData(res.data.data)
                })
            axios.get(`${api}/roles/list`)
                .then(res => {
                    setRoles(res.data.roles)
                })
        }
    }, [id, cookie, mount, navigate, removeCookie])
    return (
        <div>
            <Nav>
                <NavLink to="/login" onClick={() => removeCookie('session-token')} className="cursor-pointer h-full"><NavButton>Déconnexion</NavButton></NavLink>
            </Nav>
            <main className="grid laptop:grid-cols-3 phone:grid-cols-1 h-full justify-center items-center px-4 tablet:pt-20 phone:pt-4 tablet:pb-4 phone:pb-16">
                <div className="bg-bleen-100 col-span-1 h-full text-center">
                    {
                        ["1-mon profil", "2-mes historiques", "3-mes favoris", "4-mes notes"].map(b => 
                            <input 
                                id={b.split('-')[0]} 
                                key={b.split('-')[0]}
                                type="button"
                                value={'Consulter ' + b.split('-')[1]} 
                                className="p-2 w-full bg-bleen-200 hover:bg-bleen-300 duration-300 cursor-pointer"
                                style={{backgroundColor: b.split('-')[0] === selected && '#8CDEAE'}}
                                onClick={e => setSelected(e.target.id)}
                            />
                        )
                    }
                    
                </div>
                <div className="flex flex-col laptop:col-span-2 phone:col-span-1 h-full bg-white">
                    <div hidden={selected !== "1"}>
                        <table className="table-auto w-full text-left">
                            <tbody className="p-1" >
                                <tr className="border-b border-bleen-100">
                                    <th className="p-1 tablet:w-40 phone:w-28">Pseudo :</th>
                                    <td className="p-1">
                                        <p type="text" className="w-full pl-2 p-1">{data.username}</p>
                                    </td>
                                </tr>
                                <tr className="border-b border-bleen-100">
                                    <th className="p-1">Rôle :</th>
                                    <td className="p-1">
                                        <p type="text" className="w-full pl-2 p-1">{Object.values(roles).filter(f => f.id === data.idRole).map(v => v.name)}</p>
                                    </td>
                                </tr>
                                <tr className="border-b border-bleen-100">
                                    <th className="p-1">Email :</th>
                                    <td className="p-1">
                                        <p type="text" className="w-full pl-2 p-1">{data.email}</p>
                                    </td>
                                </tr>
                                <tr className="border-b border-bleen-100">
                                    <th className="p-1">Parrain :</th>
                                    <td className="p-1">
                                        <p type="text" className="w-full pl-2 p-1">{data.godfather}</p>
                                    </td>
                                </tr>
                                <tr className="border-b border-bleen-100">
                                    <th className="p-1">Code otaku  :</th>
                                    <td className="p-1">
                                        <p type="text" className="w-full pl-2 p-1">{data.ownReferral}</p>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div hidden={selected !== "2"}>
                        <div className="overflow-y-scroll max-h-[800px]">
                            <table className="table-auto w-full text-left">
                                <thead className="p-1">
                                    <tr className="border-b border-bleen-100">
                                        <th className="p-1 tablet:w-52 phone:w-24">Anime</th>
                                        <th className="p-1">Position</th>
                                        <th className="p-1">Type</th>
                                        <th className="p-1">Date</th>
                                    </tr>
                                </thead>
                                <tbody className="p-1">
                                {Object.values(histories).filter(f => f.idUser === parseInt(id) && f.number !== f[charToData[f.type][0]]).map(v => 
                                        <tr className="border-b border-bleen-100" key={Math.random()}>
                                            <td className="p-1">
                                                <p type="text" className="p-1 w-full">
                                                    {v.title}
                                                </p>
                                            </td>
                                            <td className="p-1">
                                                <p type="text" className="p-1 w-full">
                                                    {v.number !== v[charToData[v.type][0]] ? `${v.number}/${v[charToData[v.type][0]]}` : "Terminé"} 
                                                </p>
                                            </td>
                                            <td className="p-1">
                                                <p type="text" className="p-1 w-full">
                                                    {charToData[v.type][1]} 
                                                </p>
                                            </td>
                                            <td className="p-1">
                                                <p type="text" className="p-1 w-full">
                                                    {v.updatedAt.split("T")[0].split("-").reverse().join('/')} 
                                                </p>
                                            </td>
                                        </tr>
                                    )}
                                    {Object.values(histories).filter(f => f.idUser === parseInt(id) && f.number === f[charToData[f.type][0]]).map(v => 
                                        <tr className="border-b border-bleen-100" key={Math.random()}>
                                            <td className="p-1">
                                                <p type="text" className="p-1 w-full">
                                                    {v.title}
                                                </p>
                                            </td>
                                            <td className="p-1">
                                                <p type="text" className="p-1 w-full">
                                                    {v.number !== v[charToData[v.type][0]] ? `${v.number}/${v[charToData[v.type][0]]}` : "Terminé"} 
                                                </p>
                                            </td>
                                            <td className="p-1">
                                                <p type="text" className="p-1 w-full">
                                                    {charToData[v.type][1]} 
                                                </p>
                                            </td>
                                            <td className="p-1">
                                                <p type="text" className="p-1 w-full">
                                                    {v.updatedAt.split("T")[0].split("-").reverse().join('/')} 
                                                </p>
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div hidden={selected !== "3"}>
                        <div className="overflow-y-scroll max-h-[800px]">
                            <table className="table-auto w-full text-left">
                                <thead className="p-1">
                                    <tr className="border-b border-bleen-100">
                                        <th className="p-1 tablet:w-52 phone:w-24">Anime</th>
                                        <th className="p-1">Date</th>
                                    </tr>
                                </thead>
                                <tbody className="p-1">
                                    {Object.values(favorites).filter(f => f.idUser === parseInt(id) ).map(v => 
                                        <tr className="border-b border-bleen-100" key={Math.random()}>
                                            <td className="p-1">
                                                <p type="text" className="p-1 w-full">
                                                    {v.title} 
                                                </p>
                                            </td>
                                            <td className="p-1">
                                                <p type="text" className="p-1 w-full">
                                                    {v.updatedAt.split("T")[0].split("-").reverse().join('/')} 
                                                </p>
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div hidden={selected !== "4"}>
                        <div className="overflow-y-scroll max-h-[800px]">
                            <table className="table-auto w-full text-left">
                                <thead className="p-1">
                                    <tr className="border-b border-bleen-100">
                                        <th className="p-1 tablet:w-52 phone:w-24">Anime</th>
                                        <th className="p-1">Total</th>
                                        <th className="p-1">Graphisme</th>
                                        <th className="p-1">Histoire</th>
                                        <th className="p-1">Personnage</th>
                                        <th className="p-1">Coeur</th>
                                        <th className="p-1">Date</th>
                                    </tr>
                                </thead>
                                <tbody className="p-1">
                                    {Object.values(scores).filter(s => s.id === parseInt(id)).map(v => 
                                        <tr className="border-b border-bleen-100" key={Math.random()}>
                                            <td className="p-1">
                                                <p type="text" className="p-1 w-full">{v.title}</p>
                                            </td>
                                            <td className="p-1">
                                                <p type="text" className="p-1 w-full">{v.beauty + v.history + v.character + v.heart}/20</p>
                                            </td>
                                            <td className="p-1">
                                                <p type="text" className="p-1 w-full">{v.beauty}</p>
                                            </td>
                                            <td className="p-1">
                                                <p type="text" className="p-1 w-full">{v.history}</p>
                                            </td>
                                            <td className="p-1">
                                                <p type="text" className="p-1 w-full">{v.character}</p>
                                            </td>
                                            <td className="p-1">
                                                <p type="text" className="p-1 w-full">{v.heart}</p>
                                            </td>
                                            <td className="p-1">
                                                <p type="text" className="p-1 w-full">
                                                    {v.updatedAt.split("T")[0].split("-").reverse().join('/')} 
                                                </p>
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    );
};

export default Profile