import React, { useEffect, useState } from 'react';

const FieldRadio = ({children, condition}) => {

    const [color, setColor] = useState()
    const [check, setCheck] = useState()

    useEffect(() => {
        if(condition){
            setColor('green')
            setCheck('check')
        }
        else {
            setColor('red')
            setCheck('close')
        }
    }, [condition])

    return <div className="text-sm flex" style={{color: color}}><img src={`/images/svg/${check}.svg`} alt="status" className='w-3 mr-1'/>{children}</div>
};

export default FieldRadio;