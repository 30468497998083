import React from 'react';
import { useState } from 'react';

const UploadVideo = ({data, onChange}) => {
    const [progress, setProgress] = useState(0)
    const [size, setSize] = useState(0)
    return (
        <div>
            <input 
                type="file" 
                name="file" 
                className="w-full file:h-full file:cursor-pointer cursor-pointer border border-bleen-100 rounded-l-full file:bg-bleen-200 file:hover:bg-bleen-300 file:border-none file:p-2 file:rounded-l-full file:text-bleen-800" 
                accept='.mp4'
                onChange={e => onChange(e, data.number, setProgress) & setSize(e.target.files[0].size)}
                hidden={progress !== 0}
            />
            <div className='w-full bg-bleen-100 rounded-full h-6' hidden={progress === 0} >
                <div className='bg-bleen-300 h-full rounded-full text-center' style={{width: size !== 0 ? `${progress*100/size}%` : '0%'}}>
                    <span>{size !== 0 ? Math.round(progress*100/size): '0'}%</span>
                </div>
            </div>
        </div>
    );
};

export default UploadVideo;