import axios from 'axios';
import { useLocation } from 'react-router-dom';
import { api, domaine } from '../utils/functions';
import { useCookies } from 'react-cookie';

const Report = ({actual, isOpen, setIsOpen}) => {

    const [cookie] = useCookies(['session-token'])
    const location = useLocation()

    const handleSubmit = (e) => {
        e.preventDefault()
        const data = {
            message: e.target.msg.value,
            url: domaine + location.pathname
        }
        axios.post(`${api}/anime/report`,data, {headers: {Autorization: cookie['session-token']}})
        setIsOpen(false)
        e.target.msg.value = ""
    }

    return (
        <div className="absolute w-full h-screen flex justify-center items-center align-middle" style={{visibility: isOpen ? 'visible' : 'hidden'}}>
            <div className="bg-white z-50 p-2 border border-red-300 duration-700" style={{transform: isOpen ? '' : 'translateY(-800px)'}}>
                <div className="w-full flex justify-between h-6 mb-2">
                    <h1 className='text-red-500 text-lg font-medium'>Signaler un problème</h1>
                    <img src="/images/svg/close.svg" alt="close" height="20px" width="20px" className='cursor-pointer' onClick={_ => setIsOpen(false)}/>
                </div>
                <form onSubmit={handleSubmit} className="flex flex-col">
                    <textarea name="msg" cols="30" rows="4" className="border border-red-300 p-1 focus:outline outline-1 focus:outline-red-500 mb-2" placeholder="Merci d'écrire la raison de votre signalement (épisode qui ne fonctionne pas, fautes d'orthographes, etc...). Je corrigerais l'erreur dans les 2h" spellCheck={false}/>
                    <input type="submit" value="Envoyer le rapport" className="bg-red-600 p-2 font-bold text-white cursor-pointer hover:bg-red-800"/>
                </form>
            </div>
        </div>
    );
};

export default Report;