import React, { useEffect } from 'react';
import { useState } from 'react';


const ScoreInput = ({value, name, data, onChange}) => {
    return (
        <div className="relative w-full">
            <label htmlFor={`${name}-${value}`} className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-xl lining-nums cursor-pointer">{value}</label>
            <input className="appearance-none bg-bleen-300 checked:bg-bleen-500 w-full h-full cursor-pointer" value={value} type="radio" onChange={e => onChange(parseInt(e.target.value))} name={name} id={`${name}-${value}`} checked={data === value} />
        </div>  
    )
}

const ScoreDiv = ({titre, description, name, data, onChange}) =>  {
    return (
        <div className="h-auto w-full my-2 border border-bleen-100 p-2">
            <h2 className="text-xl">{titre} <p className="text-sm">({description})</p></h2>
            <div className="bg-bleen-200 h-8 flex p-1">
                {[0,1,2,3,4,5].map(n => <ScoreInput key={n} value={n} name={name} data={data} onChange={onChange}></ScoreInput>)}
            </div>
        </div>
    )
}

const Scores = ({isOpen, setIsOpen, onClick, scores}) => {
    const [mount, setMount] = useState(false)
    const [beauty, setBeauty] = useState(0)
    const [history, setHistory] = useState(0)
    const [character, setCharacter] = useState(0)
    const [heart, setHeart] = useState(0)
    const [comment, setComment] = useState('')
    
    useEffect(_ => {
        if(scores.beauty !== undefined && !mount) {
            setBeauty(scores.beauty)
            setHistory(scores.history)
            setCharacter(scores.character)
            setHeart(scores.heart)
            setComment(scores.comment||"")
            setMount(true)
        }
    }, [scores, mount])
    return (
        <div className="fixed z-100 flex justify-center w-full">
            <div className="bg-white z-50 h-fit p-2 pt-0 phone:w-3/4 tablet:w-3/5 laptop:w-1/2 desktop:w-1/3 shadow-2xl border border-bleen-500 duration-700" style={{marginTop: isOpen ? '90px' : '-1000px'}}>
                <ScoreDiv titre="Graphisme" description="La beauté de l'anime" name="beauty" data={beauty} onChange={setBeauty}/>
                <ScoreDiv titre="Histoire" description="L'originalité de l'histoire" name="history" data={history} onChange={setHistory}/>
                <ScoreDiv titre="Personnage" description="A quel point tu aimes les personnages" name="character" data={character} onChange={setCharacter}/>
                <ScoreDiv titre="Coeur" description="A quel point tu aimes l'anime en général" name="heart" data={heart} onChange={setHeart}/>
                <textarea className='w-full border border-bleen-100 h-52 resize-none p-2 mb-1' value={comment} onChange={e=>setComment(e.target.value)} placeholder="Commentaire sur l'animé, votre avis personnel :D" cols="30" rows="10"/>
                <div className="flex justify-center w-full">
                    <input onClick={_ => setIsOpen(false)} type="button" value="Annuler" className="phone:w-full mr-1 tablet:w-3/5 laptop:w-1/2 desktop:w-1/3  h-14 bg-red-300 hover:bg-red-400 cursor-pointer" />
                    <input onClick={_ => onClick(beauty, history, character, heart, comment)} type="button" value="Valider" className="phone:w-full ml-1 tablet:w-3/5 laptop:w-1/2 desktop:w-1/3  h-14 bg-bleen-300 hover:bg-bleen-400 cursor-pointer" />
                </div>
            </div>
        </div>
    );
};

export default Scores;