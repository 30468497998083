import React, { useEffect, useRef, useState } from 'react';
import {datas} from '../utils/functions'
import { Link } from 'react-router-dom';
const Carousel = ({children}) => {
    const [selected, setSelected] = useState(0)
    const size = Object.keys(children).length

    const radio = useRef(null)

    useEffect(() =>{
        const interval = setInterval(() => handleClick(1), 5000)
        return () => clearInterval(interval)
    })

    const handleClick = (side) => setSelected(selected + side > size-1 ? 0 : (selected + side < 0 ? size-1 : selected + side))

    return (
        <div className='w-full desktop:h-[520px] laptop:h-[420px] tablet:h-[320px] phone:h-[210px] h-72 relative tablet:top-24 phone:top-6 tablet:mb-40 phone:mb-20'>
            {Object.entries(children).map(([key, value]) => <img className={`w-full h-full blur brightness-75 absolute duration-300`} style={{opacity: selected === parseInt(key) ? 1 : 0}} src={`${datas}/${value.cleanTitle}/${value.cleanTitle}.png`} alt={`${value.cleanTitle}.png`} key={key}/>)}
            <div className='flex w-full h-full justify-between items-center'>
                <img onClick={() => handleClick(-1)} className="h-16 z-30 bg-gray-100/30 hover:bg-gray-100/60 py-4 rounded-r-md duration-200 cursor-pointer" src="/images/svg/arrowleft.svg" alt="arrowleft"></img>
                <img onClick={() => handleClick(1)} className="h-16 z-30 bg-gray-100/30 hover:bg-gray-100/60 py-4 rounded-l-md duration-200 cursor-pointer" src="/images/svg/arrowright.svg" alt="arrowright"></img>
            </div>
            <div className='flex w-full justify-center z-20'>
            {Object.entries(children).map(([key, value]) => <Link to={`/anime/${value.cleanTitle}`} className="h-full top-0 absolute" style={{zIndex: selected === parseInt(key) ? 20 : 0}} key={key}><img className={`h-full cursor-pointer shadow-xl rounded scale-105 duration-300`}  style={{opacity: selected === parseInt(key) ? 1 : 0}} src={`${datas}/${value.cleanTitle}/${value.cleanTitle}.png`} alt={`${value.cleanTitle}.png`} key={key}/></Link>)}
                <div ref={radio} className="absolute bottom-0 z-30 laptop:w-2/3 phone:w-5/6 -mb-8">
                    {Object.keys(children).map((key) => <input key={key} value={key} type="radio" className='appearance-none h-2 bg-bleen-100 checked:bg-bleen-500 top-0 transition duration-200 cursor-pointer' name='selected' style={{width: `calc(100%/${size})`}} checked={selected === parseInt(key)} onChange={(e) => setSelected(parseInt(e.target.value))}></input>)}
                </div>
            </div>
        </div>
    );
};

export default Carousel;