import React, { useRef } from 'react';
import AnimePanel from './AnimePanel';

const SliderAnime = ({titre, filter, history=false, latest=false, data}) => {
    const wPanel = 340
    let slider = useRef(null)

    const handleClick = sign => slider.current.scrollLeft+=wPanel*parseInt(slider.current.clientWidth/wPanel)*sign

    return (
        <div className={"tablet:pb-4 phone:pb-4 mt-10"}>
            <h1 className='text-center text-2xl mb-2'>{titre}</h1>
            <div className="w-full flex items-center h-full relative">
                <img onClick={_ => handleClick(-1)} className="h-16 z-30 bg-gray-100 py-4 rounded-r-md duration-200 cursor-pointer" src="/images/svg/arrowleft.svg" alt="arrowleft"/>
                <div ref={slider} className="flex scroll scroll-smooth overflow-x-scroll w-full snap-mandatory snap-x tablet:mx-2 phone:mx-0.5 scrollbar-hide">
                        {Object.values(data).filter(filter).map(value => <AnimePanel width={wPanel} key={Math.random()} latest={latest} history={history} className={['mx-3 phone:mx-1 tablet:snap-start phone:snap-center tablet:scale-100 bphone:scale-95 phone:scale-90', '']}>{value}</AnimePanel>)}
                </div>
                <img onClick={_ => handleClick(1)} className="h-16 z-30 bg-gray-100 py-4 rounded-l-md duration-200 cursor-pointer" src="/images/svg/arrowright.svg" alt="arrowright"/>
            </div>
        </div>
    );
};

export default SliderAnime;