import React, { useState } from 'react';

const Saison = ({saison, actual, nbEp, children}) => {
    const [closed, setClosed] = useState(true)
    const height = `${((saison.end || nbEp) - (saison.start-1))*24}px`
    return (
        <div key={saison.number} className="bg-white col-span-1 p-2 cursor-pointer hover:bg-gray-50" onClick={() => setClosed(!closed)}>
            <h2 className="text-xl border-b-2 border-bleen-200" style={{color: actual.saison === saison.title && actual.type === "e" ? 'rgb(52,178,104)' : ''}}>{saison.title}</h2>
            <div className='flex flex-col overflow-hidden duration-700' style={{height: closed ? '0px': height}}>
                {children}
            </div>
        </div>
    );
};

export default Saison;