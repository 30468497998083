import React, { useState } from 'react';
import axios from 'axios'
import { api } from '../utils/functions';
import Alerts from '../utils/alerts';
import FieldInput from '../components/FieldInput';
import FieldRadio from '../components/FieldRadio';

import { useSearchParams, useNavigate } from 'react-router-dom';

const Reset = () => {

    const [password, setrPassword] = useState("")
    const [password2, setrPassword2] = useState("")

    const [searchParams, ] = useSearchParams()

    let navigate = useNavigate()
    const handleSubmit = (e) => {
        e.preventDefault()
        if(!/^(?=.*[a-z])(?=.*[A-Z])(?=.*[\d]).{8,20}$/.test(password)) return Alerts(400, "Le mot de passe indiqué ne respecte pas les conditions")
        if(password !== password2) return Alerts(400, "Les deux mots de passe ne correspondent pas")

        axios.post(api + '/users/reset', {id: searchParams.get('id'), time: searchParams.get('time'), password: password})
        .then((res) => {
            navigate('/login', {replace: true})
            Alerts(res.status, res.data.success)
        })
        .catch((err) => console.log(err));
    }
   
    return (
        <div>
            <div className='tablet:my-20 phone:my-10 mx-auto shadow-lg shadow-slate-500 tablet:flex tablet:w-min phone:w-[340px]'>
                <div className='bg-white mx-auto duration-1000 tablet:w-[300px] tablet:mt-0' id="register">
                    <h1 className='text-center text-2xl pt-6 mb-10'>RÉINITIALISER LE MOT DE PASSE</h1>
                    <form onSubmit={(e) => handleSubmit(e)}>
                        <FieldInput type="password" name="password" placeholder="Mot de passe" onChange={setrPassword}></FieldInput>
                        <div className='p-1 pl-2 tablet:mb-5 tablet:-mt-4 phone:-mt-1 phone:mb-2 border border-bleen-400 rounded desktop:w-[260px] desktop:ml-[20px] tablet:w-[280px] tablet:ml-[10px] phone:w-[300px] phone:ml-[20px]'>
                            <FieldRadio condition={password.length >= 8 && password.length <= 20}>Entre <b>8 et 20 charactères</b></FieldRadio>
                            <FieldRadio condition={/[a-z]/.test(password)}>Au moins <b>une minuscule</b></FieldRadio>
                            <FieldRadio condition={/[A-Z]/.test(password)}>Au moins <b>une majuscule</b></FieldRadio>
                            <FieldRadio condition={/[\d]/.test(password)}>Au moins <b>un chiffre</b></FieldRadio>
                        </div>
                        <FieldInput type="password" name="password2" placeholder="Confirmation" onChange={setrPassword2}></FieldInput>
                        {password2.length > 0 && <p className="p-1 pl-2 tablet:mb-5 tablet:-mt-4 phone:-mt-1 phone:mb-2 border border-bleen-400 rounded desktop:w-[260px] desktop:ml-[20px] tablet:w-[280px] tablet:ml-[10px] phone:w-[300px] phone:ml-[20px] text-sm" style={{color: password === password2 ? 'green' : 'red'}}>{password === password2 ? '✅ Les mots de passe sont identiques' : '❌ Les mots de passe sont différents'}</p>}
                        <input type="submit" value="RÉINITIALISER" className='bg-white border border-bleen-400 p-2 mt-8 rounded-full tablet:w-[180px] tablet:mx-[60px] phone:w-[180px] phone:mx-[80px] cursor-pointer hover:bg-bleen-100 duration-300 mb-4'/>
                    </form>
                </div>
            </div>
        </div>
    )
};

export default Reset;