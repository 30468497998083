import React from 'react';
import { NavLink } from 'react-router-dom';
import { datas } from '../utils/functions';
const nbToXml = (nb) => <font className="inline-block lining-nums">{nb}<font className="text-xs align-top">{nb === 1 ? 'er' : 'e'}</font></font>
const AnimePanel = ({children, className=['',''], history, latest, width=340}) => { 

    const ltti = {
        épisode: ['e', 'nbEp'],
        film: ['f', 'nbFl'],
        OAV: ['o', 'nbOa']
    }
    return (
        <NavLink className={className[0]} to={`/anime/${children.cleanTitle}${history ? `/${children.type}/${children.number}` : (latest && children.nb === 1 ? `/${ltti[children.type][0]}/${children.current}` : '')}`}>
            <div className={'bg-white cursor-pointer AnimePanel ' + className[1]} style={{width: width}}>
                {children.ended ? <img src="/images/svg/check.svg" className='absolute z-10 top-0 right-0 w-6 p-1 rounded-b-md mt-2 bg-white mr-4' alt="check"/>: ''}
                <div className='overflow-hidden'>
                    <img className="duration-300 AnimeImg" src={`${datas}/${children.cleanTitle}/${children.cleanTitle}.png`} alt={`${children.cleanTitle}.png`} />
                </div>
                <div className="p-2 h-24 grid grid-cols-6 gap-1 justify-center">
                    <div className="col-span-5 font-medium uppercase max-w-[300px] flex items-end">{children.title}</div>
                    <div className="col-span-1 row-span-2 relative">
                        <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
                            <svg className="w-[70px] h-[70px] mx-auto">
                                <circle cx="35" cy="35" r="27" fill='none' strokeWidth='4' stroke="#50CC83" strokeDashoffset={`${170-(children.score !== "Non noté" ? children.score : 0)/20*170}`} strokeDasharray='170' className='duration-500 -rotate-90 translate-y-full'></circle>
                            </svg>
                            <span className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">{children.score.replace('.',',')}</span>
                        </div>
                    </div>
                    <div className='col-span-5 flex items-start'>
                        {latest 
                            ?   <div>
                                    {children.type === "épisode" && !children.seasonByArc && children.season != null 
                                        ? <p className="lining-nums">{`${children.nb < 10 ? children.season : ''} `}{children.nb === 1 ? nbToXml(children.current - children.start + 1) : children.nb} {children.type}{children.nb === 1 ? '' : 's'} le {children.latest.split('T')[0].split('-').reverse().slice(0,2).join('/')}</p>
                                        : <p className="lining-nums">{children.nb === 1 ? nbToXml(children.current) : children.nb} {children.type}{children.nb === 1 ? '' : 's'} le {children.latest.split('T')[0].split('-').reverse().slice(0,2).join('/')}</p>
                                    }
                                </div>
                            : ( history 
                                ? <p className="lining-nums">{children.number} sur {children.type === "e" ? children.nbEp + " épisodes": (children.type === "f" ? children.nbFl + " films": children.nbOa + " OAVs")}</p>
                                : <p className="lining-nums">
                                    { children.nbEp > 0 ? `${children.nbEp} épisode${children.nbEp > 1 ? 's' : ''}` : ''}
                                    { children.nbEp > 0 && (children.nbFl > 0 | children.nbOa > 0) ? (children.nbEp > 0 && children.nbFl > 0 && children.nbOa > 0 ? ', ' : ' et '): ''}
                                    { children.nbFl > 0 ? `${children.nbFl} film${children.nbFl > 1 ? 's' : ''}` : ''}
                                    { children.nbEp > 0 && children.nbFl > 0 && children.nbOa > 0 ? ` et ` : ''}
                                    { children.nbOa > 0 ? `${children.nbOa} OAV${children.nbOa > 1 ? 's' : ''}` : ''}
                                </p>)
                        }
                    </div>
                </div>
            </div>
        </NavLink>
    );
};

export default AnimePanel;