import axios from 'axios';
import React, { useEffect, useState, useRef } from 'react';
import { useCookies } from 'react-cookie'
import { api, datas} from '../../utils/functions';
import { useNavigate, useParams, NavLink } from 'react-router-dom';
import { Nav, NavButton } from '../../components/Nav';
import MultiSelector from '../../components/MultiSelector';
import UploadVideo from '../../components/admin/UploadVideo';
import NautiljonItem from '../../components/admin/NautiljonItem';

const getFullNumber = (number=0) => {
    let size = number.toString().length;
    let toReturn = number.toString()
    for(let x = 0; x < 4-size; x++) {toReturn = '0' + toReturn}
    return toReturn
}

const AAnime = () => {
    const [cookie, , removeCookie] = useCookies(['session-token'])
    const [mount, setMount] = useState(false)
    const [videos, setVideos] = useState({})
    const [nautiljon, setNautiljon] = useState("")
    const [videosEp, setVideosEp] = useState({})
    const [videosOa, setVideosOa] = useState({})
    const [videosFl, setVideosFl] = useState({})
    const [saisons, setSaisons] = useState({})
    const [anime, setAnime] = useState({});
    const [titre, setTitre] = useState("");
    const [exept, setExept] = useState(false);
    const [year, setYear] = useState("");
    const [images, setImages] = useState("");
    const [state, setState] = useState("");

    const [searchEp, setSearchEp] = useState("")
    const [searchOa, setSearchOa] = useState("")
    const [searchFl, setSearchFl] = useState("")
    const [searchSa, setSearchSa] = useState("")
    const [searchTy, setSearchTy] = useState("")
    const [searchSt, setSearchSt] = useState("")
    const [searchEt, setSearchEt] = useState("")
    const [selected, setSelected] = useState("1")
    const [showAddEp, setShowAddEp] = useState(false)
    const [showAddOa, setShowAddOa] = useState(false)
    const [showAddFl, setShowAddFl] = useState(false)
    const [showAddSa, setShowAddSa] = useState(false)
    const [showAddTy, setShowAddTy] = useState(false)
    const [showAddSt, setShowAddSt] = useState(false)
    const [showAddEt, setShowAddEt] = useState(false)

    const [newSeasonName, setNewSeasonName] = useState("")
    const [newSeasonAcronyme, setNewSeasonAcronyme] = useState("")
    const [newSeasonStart, setNewSeasonStart] = useState("")

    const [newMovieName, setNewMovieName] = useState("")
    const [newMovieAfter, setNewMovieAfter] = useState("")

    const [newOavName, setNewOavName] = useState("")
    const [newOavAfter, setNewOavAfter] = useState("")

    const [newEpisodeName, setNewEpisodeName] = useState("")
    const [newStateName, setNewStateName] = useState("")
    const [newStudioName, setNewStudioName] = useState("")
    const [newAliasName, setNewAliasName] = useState("")
    const [newLinkName, setNewLinkName] = useState("")
    const [newTypesName, setNewTypesName] = useState("")
    const [newIsTheme, setNewIsTheme] = useState(false)

    const [aType, setAType] = useState([])
    const [aStudio, setAStudio] = useState([])
    const [aAlias, setAAlias] = useState([]);
    const [aLink, setALink] = useState([]);

    const [states, setStates] = useState({})
    const [types, setTypes] = useState({})
    const [studios, setStudios] = useState({})

    const [nautiljonList, setNautiljonList] = useState([])
    const [nautiljonListPanel, setNautiljonListPanel] = useState("none")

    let navigate = useNavigate()
    const { title } = useParams()
    let refDesc = useRef(null)

    useEffect(() => {
        if (!mount) {
            setMount(true)
            if (cookie['session-token'])
                axios.post(`${api}/users/check`, { token: cookie['session-token'] })
                    .then(res => {
                        if (!res.data.correct) {
                            navigate('/login', { replace: true })
                            removeCookie('session-token')
                        }
                        else if (res.data.correct.role > 3) {
                            navigate('/animes', { replace: true })
                        }
                    })
            else
                navigate('/login', { replace: true })

            axios.get(`${api}/animes/full?title=${title}`,{headers: {Autorization: cookie['session-token']}})
                .then(res => {
                    setVideos(res.data.videos)
                    setVideosEp(res.data.videosEp)
                    setVideosFl(res.data.videosFl)
                    setVideosOa(res.data.videosOa)
                    setNautiljon(res.data.anime.nautiljon)
                    setExept(res.data.anime.seasonByArc)
                    setSaisons(res.data.saisons)
                    setAnime(res.data.anime)
                    setTitre(res.data.anime.title)
                    setImages(`${datas}/${res.data.anime.cleanTitle}/${res.data.anime.cleanTitle}.png?t=${Math.random()}`)
                    setYear(res.data.anime.year)
                    setState(res.data.anime.state)
                    setAType(res.data.types)
                    setAStudio(res.data.studios)
                    setAAlias(res.data.alias)
                    setALink(res.data.link)
                })
                .catch((err) => { navigate('/animes', { replace: true }); console.log(err) })

            axios.get(`${api}/states/list`)
                .then(res => setStates(res.data.states))

            axios.get(`${api}/types/list`)
                .then(res => setTypes(res.data.types))
            
            axios.get(`${api}/studios/list`)
                .then(res => setStudios(res.data.studios))
        }
    }, [mount, cookie, navigate, title, removeCookie])

    const actAnime = async(type, value) => {
        const data = {
            name: type === 'name' ? value : anime.title,
            state: type === 'state' ? value : Object.values(states).filter(f => f.id === anime.state).map(v => v.id)[0],
            clean: anime.cleanTitle,
            year: type === 'year' ? value : anime.year,
            desc: type === 'desc' ? value : anime.description,
            url: type === 'url' ? value : anime.nautiljon,
            arc: type === 'arc' ? value : anime.seasonByArc
        }
        if(type === 'name')window.history.replaceState(null, "Watch Anime", `/admin/animes/${value.replace(/ |:|-|\//g, '')}`)
        await axios.put(`${api}/anime?title=${anime.cleanTitle}`, data,{headers: {Autorization: cookie['session-token']}})
        await axios.get(`${api}/animes/full?title=${type === 'name' ? value.replace(/ |:|-|\//g, '') : anime.cleanTitle}`,{headers: {Autorization: cookie['session-token']}})
            .then(res => {
                setAnime(res.data.anime)
                setYear(res.data.anime.year)
                setTitre(res.data.anime.title)
                setNautiljon(res.data.anime.nautiljon)
            })
    }

    const actEpisode = async(episode, value) => {
        const data = {
            number: episode.number,
            name: value,
            type: 'e'
        }
        await axios.post(`${api}/animes/video?title=${anime.cleanTitle}`, data,{headers: {Autorization: cookie['session-token']}})
        await axios.get(`${api}/anime/videos?id=${anime.id}`)
            .then(res => setVideos(res.data.videos))

    }
    const addEpisode = async() => {
        const data = {
            number: Object.values(videos).filter(f => f.type === 'e').length +1,
            name: newEpisodeName.trim(),
            type: 'e'
        }
        await axios.post(`${api}/animes/video?title=${anime.cleanTitle}`, data,{headers: {Autorization: cookie['session-token']}})
        await axios.get(`${api}/anime/videos?id=${anime.id}`)
            .then(res => setVideos(res.data.videos))
        setNewEpisodeName("")
    }

    const actMovie = async(movie, type, value) => {
        const data = {
            number: movie.number,
            name: type === "name" ? value: movie.title,
            after: type === "after" ? value : movie.after,
            type: 'm'
        }
        await axios.post(`${api}/animes/video?title=${anime.cleanTitle}`, data,{headers: {Autorization: cookie['session-token']}})
        await axios.get(`${api}/anime/videos?id=${anime.id}`)
            .then(res => setVideos(res.data.videos))

    }
    const addMovie = async() => {
        const data = {
            number: Object.values(videos).filter(f => f.type === 'm').length +1,
            name: newMovieName.trim(),
            after: newMovieAfter,
            type: 'm'
        }
        await axios.post(`${api}/animes/video?title=${anime.cleanTitle}`, data,{headers: {Autorization: cookie['session-token']}})
        await axios.get(`${api}/anime/videos?id=${anime.id}`)
            .then(res => setVideos(res.data.videos))
        setNewMovieName("")
        setNewMovieAfter("")
    }

    const actOav = async(oav, type, value) => {
        const data = {
            number: oav.number,
            name: type === "name" ? value: oav.title,
            after: type === "after" ? value : oav.after,
            type: 'o'
        }
        await axios.post(`${api}/animes/video?title=${anime.cleanTitle}`, data,{headers: {Autorization: cookie['session-token']}})
        await axios.get(`${api}/anime/videos?id=${anime.id}`)
            .then(res => setVideos(res.data.videos))

    }
    const addOav = async() => {
        const data = {
            number: Object.values(videos).filter(f => f.type === 'o').length +1,
            name: newOavName.trim(),
            after: newOavAfter,
            type: 'o'
        }
        await axios.post(`${api}/animes/video?title=${anime.cleanTitle}`, data,{headers: {Autorization: cookie['session-token']}})
        await axios.get(`${api}/anime/videos?id=${anime.id}`)
            .then(res => setVideos(res.data.videos))
        setNewOavName("")
        setNewOavAfter("")
    }

    const actSaison = async(saison, type, value) => {
        const data = {
            number: saison.number,
            name: type === "name" ? value : saison.title,
            acronyme: type === "acro" ? value : saison.acronyme,
            start: type === "start" ? value : saison.start,
            end: type === "end" ? (value === "" ? null : value)  : saison.end
        }
        await axios.post(`${api}/animes/season?title=${anime.cleanTitle}`, data,{headers: {Autorization: cookie['session-token']}})

        await axios.get(`${api}/anime/videos?id=${anime.id}`)
            .then(res => setVideos(res.data.videos))
    }
    const addSaison = async() => {
        const data = {
            number: Object.keys(saisons).length +1,
            name: newSeasonName || `Saison ${Object.keys(saisons).length +1}`,
            acronyme: newSeasonAcronyme,
            start: Number(newSeasonStart) || Object.keys(saisons).length > 0 ? Object.values(videos).filter(f => f.type === 'e').length +1 : 1,
            end: Number(newSeasonStart)-1
        }
        await axios.post(`${api}/animes/season?title=${anime.cleanTitle}`, data,{headers: {Autorization: cookie['session-token']}})
        await axios.get(`${api}/animes/full?title=${anime.cleanTitle}`,{headers: {Autorization: cookie['session-token']}})
            .then(res => {
                setSaisons(res.data.saisons)
            })
        setNewSeasonName("")
        setNewSeasonAcronyme("")
        setNewSeasonStart("")
    }

    const actAlias = async(alias, value) => {
        const data = {
            number: alias.number,
            name: value
        }
        await axios.post(`${api}/animes/alias?title=${anime.cleanTitle}`, data,{headers: {Autorization: cookie['session-token']}})

        await axios.get(`${api}/animes/full?title=${anime.cleanTitle}`,{headers: {Autorization: cookie['session-token']}})
            .then(res => {
                setAAlias(res.data.alias)
            })
    }
    const addAlias = async() => {
        const data = {
            number: aAlias.length +1,
            name: newAliasName
        }
        await axios.post(`${api}/animes/alias?title=${anime.cleanTitle}`, data,{headers: {Autorization: cookie['session-token']}})
        await axios.get(`${api}/animes/full?title=${anime.cleanTitle}`,{headers: {Autorization: cookie['session-token']}})
            .then(res => {
                setAAlias(res.data.alias)
            })
        setNewAliasName("")
    }

    const actLink = async(link, value) => {
        const data = {
            number: link.number,
            name: value
        }
        await axios.post(`${api}/animes/link?title=${anime.cleanTitle}`, data,{headers: {Autorization: cookie['session-token']}})

        await axios.get(`${api}/animes/full?title=${anime.cleanTitle}`,{headers: {Autorization: cookie['session-token']}})
            .then(res => {
                setALink(res.data.link)
            })
    }
    const addLink = async() => {
        const data = {
            number: aLink.length +1,
            name: newLinkName
        }
        await axios.post(`${api}/animes/link?title=${anime.cleanTitle}`, data,{headers: {Autorization: cookie['session-token']}})
        await axios.get(`${api}/animes/full?title=${anime.cleanTitle}`,{headers: {Autorization: cookie['session-token']}})
            .then(res => {
                setALink(res.data.link)
            })
        setNewLinkName("")
    }

    const addBelong = async(v) => {
        await axios.post(`${api}/animes/belong?title=${anime.cleanTitle}`, {id: v.id},{headers: {Autorization: cookie['session-token']}})
        await axios.get(`${api}/animes/full?title=${anime.cleanTitle}`,{headers: {Autorization: cookie['session-token']}})
            .then(res => {
                setAType(res.data.types)
            })
    }
    const delBelong = async(v) => {
        await axios.delete(`${api}/animes/belong?title=${anime.cleanTitle}&id=${v.id}`,{headers: {Autorization: cookie['session-token']}})
        await axios.get(`${api}/animes/full?title=${anime.cleanTitle}`,{headers: {Autorization: cookie['session-token']}})
            .then(res => {
                setAType(res.data.types)
            })
    }

    const addProducer = async(v) => {
        await axios.post(`${api}/animes/producer?title=${anime.cleanTitle}`, {id: v.id},{headers: {Autorization: cookie['session-token']}})
        await axios.get(`${api}/animes/full?title=${anime.cleanTitle}`,{headers: {Autorization: cookie['session-token']}})
            .then(res => {
                setAStudio(res.data.studios)
            })
    }
    const delProducer = async(v) => {
        await axios.delete(`${api}/animes/producer?title=${anime.cleanTitle}&id=${v.id}`,{headers: {Autorization: cookie['session-token']}})
        await axios.get(`${api}/animes/full?title=${anime.cleanTitle}`,{headers: {Autorization: cookie['session-token']}})
            .then(res => {
                setAStudio(res.data.studios)
            })
    }

    const actType = async(typ, type, value) => {
        const data = {
            id:typ.id,
            name: type === 'name' ? value : typ.name,
            isTheme: type === 'theme' ? value : typ.isTheme
        }
        await axios.post(`${api}/animes/type`, data,{headers: {Autorization: cookie['session-token']}})
        await axios.get(`${api}/types/list`)
            .then(res => setTypes(res.data.types))

    }
    const addType = async(name = newTypesName, isTheme = newIsTheme) => {
        const data = {
            name: name,
            isTheme: isTheme
        }
        await axios.post(`${api}/animes/type`, data,{headers: {Autorization: cookie['session-token']}})
        await axios.get(`${api}/types/list`)
            .then(res => setTypes(res.data.types))
        setNewTypesName("")
    }

    const actStudio = async(studio, value) => {
        const data = {
            id:studio.id,
            name: value
        }
        await axios.post(`${api}/animes/studio`, data,{headers: {Autorization: cookie['session-token']}})
        await axios.get(`${api}/studios/list`)
            .then(res => setStudios(res.data.studios))

    }
    const addStudio = async(name = newStudioName) => {
        const data = {
            name: name
        }
        await axios.post(`${api}/animes/studio`, data,{headers: {Autorization: cookie['session-token']}})
        await axios.get(`${api}/studios/list`)
            .then(res => setStudios(res.data.studios))
        setNewStudioName("")
    }

    const actState = async(state, value) => {
        const data = {
            id:state.id,
            name: value,
        }
        await axios.post(`${api}/animes/state`, data,{headers: {Autorization: cookie['session-token']}})
        await axios.get(`${api}/states/list`)
            .then(res => setStates(res.data.states))

    }
    const addState = async() => {
        const data = {
            name: newStateName
        }
        await axios.post(`${api}/animes/state`, data,{headers: {Autorization: cookie['session-token']}})
        await axios.get(`${api}/states/list`)
            .then(res => setStates(res.data.states))
        setNewStateName("")
    }

    const uploadPoster = async(e) => {
        const image = new FormData()
        image.append(`file`, e.target.files[0])
        await axios.post(`${api}/upload?type=posters&name=${anime.cleanTitle}`, image, {headers: {Autorization: cookie['session-token'], "Content-Type": "multipart/form-data"}})
        await axios.get(`${api}/animes/full?title=${anime.cleanTitle}`,{headers: {Autorization: cookie['session-token']}})
        .then(res => {
            setImages(`${datas}/${res.data.anime.cleanTitle}/${res.data.anime.cleanTitle}.png?t=${Math.random()}`)
        })
        e.target.files[0] = {}
    }

    const uploadEpisode = async(e, nb, progress) => {
        const video = new FormData()
        video.append(`file`, e.target.files[0])
        await axios.post(`${api}/upload?type=series&name=${anime.cleanTitle}-${nb}`, video, {headers: {Autorization: cookie['session-token'], "Content-Type": "multipart/form-data"}, onUploadProgress: e => progress(e.loaded)})
        await axios.get(`${api}/animes/full?title=${anime.cleanTitle}`,{headers: {Autorization: cookie['session-token']}})
        .then(res => {
            setVideosEp(res.data.videosEp)
        })
    }

    const uploadMovie = async(e, nb, progress) => {
        const video = new FormData()
        video.append(`file`, e.target.files[0])
        await axios.post(`${api}/upload?type=movies&name=${anime.cleanTitle}-${nb}`, video, {headers: {Autorization: cookie['session-token'], "Content-Type": "multipart/form-data"}, onUploadProgress: e => progress(e.loaded)})
        await axios.get(`${api}/animes/full?title=${anime.cleanTitle}`,{headers: {Autorization: cookie['session-token']}})
        .then(res => {
            setVideosFl(res.data.videosFl)
        })
    }

    const uploadOav= async(e, nb, progress) => {
        const video = new FormData()
        video.append(`file`, e.target.files[0])
        await axios.post(`${api}/upload?type=oavs&name=${anime.cleanTitle}-${nb}`, video, {headers: {Autorization: cookie['session-token'], "Content-Type": "multipart/form-data"}, onUploadProgress: e => progress(e.loaded)})
        await axios.get(`${api}/animes/full?title=${anime.cleanTitle}`,{headers: {Autorization: cookie['session-token']}})
        .then(res => {
            setVideosOa(res.data.videosOa)
        })
    }


    const searchInNautiljonList = async() => {
        if(!nautiljon || (saisons.length >= 2 && Object.values(videos).filter(f => f.type === 'e').length - saisons.slice(-2)[0].end === 0)) {
            let res = await axios.get(`${api}/animes/nautiljon/list?titre=${titre}&serie=1`)
                .then(res => {
                    setNautiljonList(res.data.info)
                    if(res.data.info.length > 1)
                        setNautiljonListPanel("flex")
                    return res.data.info
                })
            if(res.length === 1)
                await searchInNautiljon(res[0].url)
        }
        else
            await searchInNautiljon(nautiljon)
    }

    const searchInNautiljon = async(url) => {
        await axios.post(`${api}/animes/nautiljon/episodes?titre=${titre}&onArk=${exept}`,{url:url})
        .then(async() => {
            setNautiljonList([])
            setNautiljonListPanel("none")
            await axios.get(`${api}/anime/videos?id=${anime.id}`)
                .then(res => setVideos(res.data.videos))
                .catch((err) => { navigate('/animes', { replace: true }); console.log(err) })
        })
        .catch(err => console.log(err))
        await actAnime('url', url) 
    }

    const deleteVideo= async(type, number) => {
        await axios.delete(`${api}/animes/video?title=${anime.cleanTitle}&number=${number}&type=${type}`,{headers: {Autorization: cookie['session-token']}})
        await axios.get(`${api}/anime/videos?id=${anime.id}`)
            .then(res => setVideos(res.data.videos))
    }
    return (
        <div>
            <Nav>
                <NavLink to="/admin/animes" className="cursor-pointer h-full"><NavButton className="mr-0.5">Administration</NavButton></NavLink>
            </Nav>
            <main className="grid laptop:grid-cols-3 phone:grid-cols-1 h-full justify-center items-center px-4 tablet:pt-20 phone:pt-4 tablet:pb-4 phone:pb-16">
                <div className="bg-bleen-100 col-span-1 h-full text-center">
                    {
                        ["1-l'animé", "2-les épisodes", "3-les saisons", "4-les films", "5-les oavs", "6-les genres", "7-les studios", "8-les états de sortie"].map(b => 
                            <input 
                                id={b.split('-')[0]} 
                                key={b.split('-')[0]}
                                type="button"
                                value={'Modifier ' + b.split('-')[1]} 
                                className="p-2 w-full bg-bleen-200 hover:bg-bleen-300 duration-300 cursor-pointer"
                                style={{backgroundColor: b.split('-')[0] === selected && '#8CDEAE'}}
                                onClick={e => setSelected(e.target.id)}
                            />
                        )
                    }
                    
                </div>
                <div style={{display: nautiljonListPanel}} className="absolute flex flex-wrap phone:justify-center tablet:justify-between p-2 bg-white h-fit z-100 top-64 border-2 border-bleen-200 rounded my-auto">{
                    nautiljonList.map(e => <NautiljonItem key={Math.random()} onClick={searchInNautiljon}>{e}</NautiljonItem>)
                }</div>
                <div className="flex flex-col laptop:col-span-2 phone:col-span-1 h-full bg-white">
                    <div hidden={selected !== "1"}>
                        <table className="table-auto w-full text-left">
                            <tbody className="p-1" >
                                <tr className="border-b border-bleen-100">
                                    <th className="p-1 tablet:w-40 phone:w-24">Titre :</th>
                                    <td className="p-1">
                                        <input 
                                            type="text" 
                                            value={titre}
                                            onChange={e => setTitre(e.target.value)}
                                            className="w-full pl-2 p-1"
                                            onBlur={e => actAnime('name', e.target.value)}
                                        />
                                    </td>
                                </tr>
                                <tr className="border-b border-bleen-100">
                                    <th className="p-1">Titre court :</th>
                                    <td className="p-1">
                                        <input 
                                            type="text" 
                                            defaultValue={anime.cleanTitle} 
                                            className="w-full pl-2 p-1" 
                                            readOnly/>
                                    </td>
                                </tr>
                                <tr className="border-b border-bleen-100">
                                    <th className="p-1">Image actuel:</th>
                                    <td className="p-1 flex">
                                        <img src={images} alt={anime.cleanTitle} className="w-24 ml-2" ></img>
                                        <div className="ml-5 w-full">
                                            <label htmlFor="posters">Changer l'image</label>
                                            <input 
                                                type="file" 
                                                name="posters"
                                                className="w-full file:h-full cursor-pointer border border-bleen-100 rounded-l-full file:bg-bleen-200 file:hover:bg-bleen-300 file:border-none file:p-2 file:rounded-l-full file:text-bleen-800" 
                                                accept='.png,.jpg'
                                                onChange={uploadPoster}
                                            />
                                        </div>
                                    </td>
                                </tr>
                                <tr className="border-b border-bleen-100">
                                    <th className="p-1">Alias :</th>
                                    <td className="p-1">
                                        <div className="flex">
                                            <input type="text" value={newAliasName} placeholder="Nouvel alias" onChange={e => setNewAliasName(e.target.value)} className="pl-2 w-full my-1"></input>
                                            <input type="button" value="Ajouter" onClick={addAlias} className="px-2 bg-bleen-200 hover:bg-bleen-300 cursor-pointer"/>
                                        </div>
                                        {aAlias.map(v => 
                                            <input 
                                                type="text" 
                                                key={v.number} 
                                                defaultValue={v.title} 
                                                className="pl-1 w-full my-1"
                                                onBlur={e => actAlias(v, e.target.value)}
                                            />
                                        )}
                                    </td>
                                </tr>
                                <tr className="border-b border-bleen-100">
                                    <th className="p-1 tablet:w-40 phone:w-24">Année :</th>
                                    <td className="p-1">
                                        <input 
                                            type="text" 
                                            value={year}
                                            onChange={e => /^\d{0,4}$/.test(e.target.value) && setYear(e.target.value)}
                                            className="w-full pl-2 p-1" 
                                            onBlur={e => actAnime('year', e.target.value)}
                                        />
                                    </td>
                                </tr>
                                <tr className="border-b border-bleen-100">
                                    <th className="p-1">Statuts :</th>
                                    <td className="p-1">
                                        <select 
                                            type="text" 
                                            className="w-full bg-white p-1 ml-1" 
                                            onChange={e => setState(e.target.value) & actAnime('state', e.target.value)}
                                            value={state}
                                        >
                                            {Object.values(states).map(s => <option key={s.id} value={s.id}>{s.name}</option>)}
                                        </select>
                                            
                                    </td>
                                </tr>
                                <tr className="border-b border-bleen-100">
                                    <th className="p-1">Studio(s) :</th>
                                    <td className="p-1 pl-3">
                                        <MultiSelector data={studios} selected={aStudio} del={delProducer} add={addProducer} _new={addStudio}></MultiSelector>
                                    </td>
                                </tr>
                                <tr className="border-b border-bleen-100">
                                    <th className="p-1">Genres :</th>
                                    <td className="p-1 pl-3">
                                        <MultiSelector data={types} selected={aType} del={delBelong} add={addBelong} _new={(name) => addType(name, false)}></MultiSelector>
                                    </td>
                                </tr>
                                <tr className="border-b border-bleen-100">
                                    <th className="p-1">Synopsis :</th>
                                    <td className="p-1">
                                        <textarea 
                                            defaultValue={anime.description} 
                                            className="w-full pl-2 p-1" 
                                            ref={refDesc}
                                            spellCheck={false}
                                            onFocus={_ => refDesc.current.style.height = `${refDesc.current.scrollHeight}px`}
                                            onBlur={e => refDesc.current.style.height = `auto` & actAnime('desc', e.target.value)}
                                        />
                                    </td>
                                </tr>
                                <tr className="border-b border-bleen-100">
                                    <th className="p-1">Saison en arc ? :</th>
                                    <td className="p-1 pl-3">
                                        <label htmlFor="green-toggle" className="inline-flex relative items-center mr-5 cursor-pointer mt-2">
                                            <input type="checkbox" id="green-toggle" className="sr-only peer" defaultChecked={anime.seasonByArc} onClick={e => actAnime('arc', e.target.checked)}/>
                                            <div className="w-11 h-6 bg-gray-200 rounded-full peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-bleen-500"></div>
                                            <span className="ml-3">Non {'<->'} Oui</span>
                                        </label>
                                    </td>
                                </tr>
                                <tr className="border-b border-bleen-100">
                                    <th className="p-1">Nautiljon :</th>
                                    <td className="p-1 pl-3 flex text-center">
                                    <input 
                                        type="text" 
                                        value={nautiljon}
                                        onChange={e => setNautiljon(e.target.value)}
                                        className="w-full"
                                        onBlur={e => actAnime('url', e.target.value)}
                                    />
                                        <a href={nautiljon} target="_blank" rel='noreferrer'><img src="/images/svg/next.svg" alt="Go" className='w-10'/></a>
                                    </td>
                                </tr>
                                <tr className="border-b border-bleen-100">
                                    <th className="p-1">Autres liens :</th>
                                    <td className="p-1">
                                        <div className="flex">
                                            <input type="text" value={newLinkName} placeholder="Nouveau lien" onChange={e => setNewLinkName(e.target.value)} className="pl-2 w-full my-1"></input>
                                            <input type="button" value="Ajouter" onClick={addLink} className="px-2 bg-bleen-200 hover:bg-bleen-300 cursor-pointer"/>
                                        </div>
                                        {aLink.map(v => <div className='flex w-full'>
                                            <input 
                                                type="text" 
                                                key={v.number} 
                                                defaultValue={v.link} 
                                                className="pl-2 w-full my-1"
                                                onBlur={e => actLink(v, e.target.value)}
                                            />
                                            <a href={v.link} target="_blank" rel='noreferrer'><img src="/images/svg/next.svg" alt="Go" className='w-10'/></a>
                                        </div>
                                        )}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div hidden={selected !== "2"}>
                        <div className="flex text-center">
                            <input type="text" value={searchEp} onChange={e => setSearchEp(e.target.value)} placeholder='Rechercher' className='p-1 w-1/2 border-b border-bleen-100'/>
                            <input type="button" value="Ajouter" className="w-1/4 bg-bleen-200 hover:bg-bleen-300 cursor-pointer" onClick={_ => setShowAddEp(!showAddEp)}/>
                            <div className='flex ml-0.5 bg-bleen-200 hover:bg-bleen-300 w-1/4 text-center'>
                                <input type="button" value="Actualiser" className="cursor-pointer w-4/5" onClick={searchInNautiljonList}/>
                                <div>
                                    <label htmlFor="green-toggle2" className="inline-flex relative items-center cursor-pointer mt-2">
                                        <input type="checkbox" id="green-toggle2" className="sr-only peer" defaultChecked={anime.seasonByArc}
                                        onClick={_ => setExept(c => !c)}/>
                                        <div className="w-11 h-6 bg-gray-200 rounded-full peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-bleen-500"></div>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className="flex border-b border-bleen-100" style={{display: !showAddEp && 'none'}}>
                            <p className="p-1 pl-2 tablet:w-52 phone:w-24">{Object.values(videos).filter(f => f.type === 'e').length +1 }</p>
                            <input type="text" value={newEpisodeName} onChange={e => setNewEpisodeName(e.target.value)} placeholder="Titre"  className='p-1 w-full'/>
                            <input type="button" value="Ajouter" onClick={addEpisode} className="px-2 bg-bleen-200 hover:bg-bleen-300 cursor-pointer"/>
                        </div>
                        <div className="overflow-y-scroll max-h-[800px]">
                            <table className="table-auto w-full text-left">
                                <thead className="p-1">
                                    <tr className="border-b border-bleen-100">
                                        <th className="p-1 w-24">Numéro</th>
                                        <th className="p-1">Titre</th>
                                        <th className="p-1 tablet:w-56 phone:w-24">Video</th>
                                    </tr>
                                </thead>
                                <tbody className="p-1">
                                    {Object.values(videos).filter(f => f.type === 'e').filter(f => new RegExp(searchEp, 'i').test(`${f.number} ${f.title}`)).sort((a, b) => b.number-a.number).map(v => 
                                        <tr className={`border-b border-bleen-${Object.keys(saisons).length >= 1 ? (anime.seasonByArc ? '100' : (v.number-(saisons[Object.keys(saisons).find(k => saisons[k].start <= v.number && (saisons[k].end >= v.number || saisons[k].end === null))].start-1) === 1 ? '500' : '100')) : '100'}`} key={v.number}>
                                            <td className="p-1 flex relative">
                                                <img className='cursor-pointer' src="/images/svg/close.svg" alt="" width={15} style={{display: v.number !== Object.values(videos).filter(f => f.type === 'e').length || videosEp.includes(`${anime.cleanTitle}-${getFullNumber(v.number)}.mp4`) ? "none" : "block"}} onClick={() => deleteVideo("e", v.number)}/>
                                                <p className="p-1">{Object.keys(saisons).length >= 1 ? (anime.seasonByArc ? v.number : v.number-(saisons[Object.keys(saisons).find(k => saisons[k].start <= v.number && (saisons[k].end >= v.number || saisons[k].end === null))].start-1)) : v.number}</p>
                                            </td>
                                            <td className="p-1">
                                                <input 
                                                    type="text" 
                                                    defaultValue={v.title} 
                                                    className="p-1 w-full" 
                                                    onBlur={e => actEpisode(v, e.target.value)}
                                                />
                                            </td>
                                            <td className="p-1">
                                                {videosEp.includes(`${anime.cleanTitle}-${getFullNumber(v.number)}.mp4`) 
                                                    ? <p>{`${anime.cleanTitle}-${getFullNumber(v.number)}.mp4`}</p>
                                                    : <UploadVideo data={v} onChange={(e, number, progress) => uploadEpisode(e, getFullNumber(number), progress)}></UploadVideo>
                                                }
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                        
                    </div>
                    <div hidden={selected !== "3"}>
                        <div className="flex text-center">
                            <input type="text" value={searchSa} onChange={e => setSearchSa(e.target.value)} placeholder='Rechercher' className='p-1 w-1/2 border-b border-bleen-100'/>
                            <input type="button" value="Ajouter une saison" className="w-1/2 bg-bleen-200 hover:bg-bleen-300 cursor-pointer" onClick={_ => setShowAddSa(!showAddSa)}/>
                        </div>
                        <div onSubmit={addSaison} className="flex border-b border-bleen-500" style={{display: !showAddSa && 'none'}}>
                            <p name="number" className="p-1 pl-2 tablet:w-52 phone:w-24">{Object.keys(saisons).length +1 }</p>
                            <input name="name" type="text" value={newSeasonName} onChange={e => setNewSeasonName(e.target.value)} placeholder={`Saison ${Object.keys(saisons).length +1}`} className='p-1 mr-1 w-full'/>
                            <input name="name" type="text" value={newSeasonStart} onChange={e => setNewSeasonStart(e.target.value)} placeholder={`Debut (${Object.keys(saisons).length > 0 ? Object.values(videos).filter(f => f.type === 'e').length +1 : 1})`} className='p-1 mr-1'/>
                            <input type="button" onClick={addSaison} value="Valider" className="px-2 bg-bleen-200 hover:bg-bleen-300 cursor-pointer"/>
                        </div>
                        <div className="overflow-y-scroll max-h-[800px]">
                            <table className="table-auto w-full text-left h-full">
                                <thead className="p-1">
                                    <tr className="border-b border-bleen-100">
                                        <th className="p-1 tablet:w-40 phone:w-24">Numéro</th>
                                        <th className="p-1">Titre</th>
                                        <th className="p-1">Acronyme</th>
                                        <th className="p-1">Debut</th>
                                        <th className="p-1">Fin</th>
                                    </tr>
                                </thead>
                                <tbody className="p-1">
                                    {Object.values(saisons).filter(f => new RegExp(searchSa, 'i').test(`${f.number} ${f.title}`)).sort((a, b) => b.number-a.number).map(v => 
                                        <tr className="border-b border-bleen-100" key={v.number}>
                                            <td className="p-1 pl-2">
                                                <p className="">{v.number}</p> 
                                            </td>
                                            <td className="p-1">
                                                <input 
                                                    type="text"
                                                    defaultValue={v.title} 
                                                    className="p-1 w-full" 
                                                    onBlur={e => actSaison(v, 'name', e.target.value)}
                                                />
                                            </td>
                                            <td className="p-1">
                                                <input 
                                                    type="text"
                                                    defaultValue={v.acronyme} 
                                                    className="p-1 w-full" 
                                                    onBlur={e => actSaison(v, 'acro', e.target.value)}
                                                />
                                            </td>
                                            <td className="p-1">
                                                <input 
                                                    type="text" 
                                                    defaultValue={v.start} 
                                                    className="p-1 w-full"
                                                    onBlur={e => actSaison(v, 'start', e.target.value)}
                                                />
                                            </td>
                                            <td className="p-1">
                                                <input 
                                                    type="text" 
                                                    defaultValue={v.end || ""} 
                                                    className="p-1 w-full" 
                                                    disabled={true}
                                                />
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div hidden={selected !== "4"}>
                        <div className="flex text-center">
                            <input type="text" value={searchFl} onChange={e => setSearchFl(e.target.value)} placeholder='Rechercher' className='p-1 w-1/2 border-b border-bleen-100'/>
                            <input type="button" value="Ajouter un film" className="w-1/2 bg-bleen-200 hover:bg-bleen-300 cursor-pointer" onClick={_ => setShowAddFl(!showAddFl)}/>
                        </div>
                        <div className="flex border-b border-bleen-100" style={{display: !showAddFl && 'none'}}>
                            <p className="p-1 pl-2 tablet:w-52 phone:w-24">{Object.values(videos).filter(f => f.type === 'm').length +1 }</p>
                            <input type="text" value={newMovieName} onChange={e => setNewMovieName(e.target.value)} placeholder="Titre"  className='p-1 w-full m-0.5'/>
                            <input type="text" value={newMovieAfter} onChange={e => /^\d{0,4}$/.test(e.target.value) && setNewMovieAfter(e.target.value)} placeholder="Après"  className='p-1 w-20 m-0.5'/>
                            <input type="button" value="Ajouter" onClick={addMovie} className="px-2 bg-bleen-200 hover:bg-bleen-300 cursor-pointer"/>
                        </div>
                        <div className="overflow-y-scroll max-h-[800px]">
                            <table className="table-auto w-full text-left">
                                <thead className="p-1">
                                    <tr className="border-b border-bleen-100">
                                        <th className="p-1 w-24">Numéro</th>
                                        <th className="p-1">Titre</th>
                                        <th className="p-1">Après</th>
                                        <th className="p-1 tablet:w-56 phone:w-24">Video</th>
                                    </tr>
                                </thead>
                                <tbody className="p-1">
                                    {Object.values(videos).filter(f => f.type === 'm').filter(f => new RegExp(searchFl, 'i').test(`${f.number} ${f.title}`)).sort((a, b) => b.number-a.number).map(v => 
                                        <tr className="border-b border-bleen-100" key={v.number}>
                                            <td className="p-1">
                                                <img className='cursor-pointer' src="/images/svg/close.svg" alt="" width={15}  style={{display: v.number !== Object.values(videos).filter(f => f.type === 'm').length || videosFl.includes(`${anime.cleanTitle}-${getFullNumber(v.number)}.mp4`)  ? "none" : "block"}} onClick={() => deleteVideo("m", v.number)}/>
                                                <p className="p-1">{v.number}</p>
                                            </td>
                                            <td className="p-1">
                                                <input 
                                                    type="text" 
                                                    defaultValue={v.title} 
                                                    className="p-1 w-full" 
                                                    onBlur={e => actMovie(v, "name", e.target.value)}
                                                />
                                            </td>
                                            <td className="p-1">
                                                <input 
                                                    type="text" 
                                                    defaultValue={v.afterEp} 
                                                    className="p-1 w-full" 
                                                    onBlur={e => actMovie(v, "after", e.target.value)}
                                                />
                                            </td>
                                            <td className="p-1">
                                                {videosFl.includes(`${anime.cleanTitle}-${getFullNumber(v.number)}.mp4`) 
                                                    ? <p>{`${anime.cleanTitle}-${getFullNumber(v.number)}.mp4`}</p>
                                                    : <UploadVideo data={v} onChange={(e, number, progress) => uploadMovie(e, getFullNumber(number), progress)}></UploadVideo>
                                                }
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                        
                    </div>
                    <div hidden={selected !== "5"}>
                        <div className="flex text-center">
                            <input type="text" value={searchOa} onChange={e => setSearchOa(e.target.value)} placeholder='Rechercher' className='p-1 w-1/2 border-b border-bleen-100'/>
                            <input type="button" value="Ajouter un oav" className="w-1/2 bg-bleen-200 hover:bg-bleen-300 cursor-pointer" onClick={_ => setShowAddOa(!showAddOa)}/>
                        </div>
                        <div className="flex border-b border-bleen-100" style={{display: !showAddOa && 'none'}}>
                            <p className="p-1 pl-2 tablet:w-52 phone:w-24">{Object.values(videos).filter(f => f.type === 'o').length +1 }</p>
                            <input type="text" value={newOavName} onChange={e => setNewOavName(e.target.value)} placeholder="Titre"  className='p-1 w-full m-0.5'/>
                            <input type="text" value={newOavAfter} onChange={e => /^\d{0,4}$/.test(e.target.value) && setNewOavAfter(e.target.value)} placeholder="Après"  className='p-1 w-20 m-0.5'/>
                            <input type="button" value="Ajouter" onClick={addOav} className="px-2 bg-bleen-200 hover:bg-bleen-300 cursor-pointer"/>
                        </div>
                        <div className="overflow-y-scroll max-h-[800px]">
                            <table className="table-auto w-full text-left">
                                <thead className="p-1">
                                    <tr className="border-b border-bleen-100">
                                        <th className="p-1 w-24">Numéro</th>
                                        <th className="p-1">Titre</th>
                                        <th className="p-1">Après</th>
                                        <th className="p-1 tablet:w-56 phone:w-24">Video</th>
                                    </tr>
                                </thead>
                                <tbody className="p-1">
                                    {Object.values(videos).filter(f => f.type === 'o').filter(f => new RegExp(searchOa, 'i').test(`${f.number} ${f.title}`)).sort((a, b) => b.number-a.number).map(v => 
                                        <tr className="border-b border-bleen-100" key={v.number}>
                                            <td className="p-1">
                                                <img className='cursor-pointer' src="/images/svg/close.svg" alt="" width={15}  style={{display: v.number !== Object.values(videos).filter(f => f.type === 'o').length || videosOa.includes(`${anime.cleanTitle}-${getFullNumber(v.number)}.mp4`)  ? "none" : "block"}} onClick={() => deleteVideo("o", v.number)}/>
                                                <p className="p-1">{v.number}</p>
                                            </td>
                                            <td className="p-1">
                                                <input 
                                                    type="text" 
                                                    defaultValue={v.title} 
                                                    className="p-1 w-full" 
                                                    onBlur={e => actOav(v, "name", e.target.value)}
                                                />
                                            </td>
                                            <td className="p-1">
                                                <input 
                                                    type="text" 
                                                    defaultValue={v.afterEp} 
                                                    className="p-1 w-full" 
                                                    onBlur={e => actOav(v, "after", e.target.value)}
                                                />
                                            </td>
                                            <td className="p-1">
                                                {videosOa.includes(`${anime.cleanTitle}-${getFullNumber(v.number)}.mp4`) 
                                                    ? <p>{`${anime.cleanTitle}-${getFullNumber(v.number)}.mp4`}</p>
                                                    : <UploadVideo data={v} onChange={(e, number, progress) => uploadOav(e, getFullNumber(number), progress)}></UploadVideo>
                                                }
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                        
                    </div>
                    <div hidden={selected !== "6"}>
                        <div className="flex text-center">
                            <input type="text" value={searchTy} onChange={e => setSearchTy(e.target.value)} placeholder='Rechercher' className='p-1 w-1/2 border-b border-bleen-100'/>
                            <input type="button" value="Ajouter un genre" className="w-1/2 bg-bleen-200 hover:bg-bleen-300 cursor-pointer" onClick={_ => setShowAddTy(!showAddTy)}/>
                        </div>
                        <div className="flex border-b border-bleen-100" style={{display: !showAddTy && 'none'}}>
                            <input type="text" value={newTypesName} onChange={e => setNewTypesName(e.target.value)} placeholder="Titre"  className='p-1 w-full'/>
                            <label className="inline-flex relative items-center mr-5 cursor-pointer mt-2 mb-1 w-1/4">
                                <input type="checkbox" className="sr-only peer" checked={newIsTheme} onChange={_ => setNewIsTheme(!newIsTheme)}/>
                                <div className="w-11 h-6 bg-gray-200 rounded-full peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-bleen-500"></div>
                                <span className="ml-3">Non {'<->'} Oui</span>
                            </label>
                            <input type="button" value="Ajouter" onClick={_ => addType()} className="px-2 bg-bleen-200 hover:bg-bleen-300 cursor-pointer"/>
                        </div>
                        <div className="overflow-y-scroll max-h-[800px]">
                            <table className="table-fixed w-full text-left">
                                <thead className="p-1">
                                    <tr className="border-b border-bleen-100">
                                        <th className="p-1 tablet:w-40 phone:w-24">Nom</th>
                                        <th className="p-1">Thème ?</th>
                                    </tr>
                                </thead>
                                <tbody className="p-1">
                                    {Object.values(types).filter(f => new RegExp(searchTy, 'i').test(`${f.name}`)).map(v => 
                                        <tr className="border-b border-bleen-100" key={v.id}>
                                            <td className="p-1">
                                                <input 
                                                    type="text" 
                                                    defaultValue={v.name} 
                                                    className="p-1 w-full" 
                                                    onBlur={e => actType(v, 'name', e.target.value)}
                                                />
                                            </td>
                                            <td className="p-1 pl-3">
                                                <label className="inline-flex relative items-center mr-5 cursor-pointer mt-2">
                                                    <input type="checkbox" className="sr-only peer" defaultChecked={v.isTheme} onChange={e => actType(v, 'theme', e.target.checked)}/>
                                                    <div className="w-11 h-6 bg-gray-200 rounded-full peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-bleen-500"></div>
                                                    <span className="ml-3">Non {'<->'} Oui</span>
                                                </label>
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div hidden={selected !== "7"}>
                        <div className="flex text-center">
                            <input type="text" value={searchSt} onChange={e => setSearchSt(e.target.value)} placeholder='Rechercher' className='p-1 w-1/2 border-b border-bleen-100'/>
                            <input type="button" value="Ajouter un studio" className="w-1/2 bg-bleen-200 hover:bg-bleen-300 cursor-pointer" onClick={_ => setShowAddSt(!showAddSt)}/>
                        </div>
                        <div className="flex border-b border-bleen-100" style={{display: !showAddSt && 'none'}}>
                            <input type="text" value={newStudioName} onChange={e => setNewStudioName(e.target.value)} placeholder="Titre"  className='p-1 w-full'/>
                            <input type="button" value="Ajouter" onClick={_ => addStudio()} className="px-2 bg-bleen-200 hover:bg-bleen-300 cursor-pointer"/>
                        </div>
                        <div className="overflow-y-scroll max-h-[800px]">
                            <table className="table-auto w-full text-left">
                                <thead className="p-1">
                                    <tr className="border-b border-bleen-100">
                                        <th className="p-1">Nom</th>
                                    </tr>
                                </thead>
                                <tbody className="p-1">
                                    {Object.values(studios).filter(f => new RegExp(searchSt, 'i').test(`${f.name}`)).map(v => 
                                        <tr className="border-b border-bleen-100" key={v.id}>
                                            <td className="p-1">
                                                <input 
                                                    type="text" 
                                                    defaultValue={v.name} 
                                                    className="p-1 w-full" 
                                                    onBlur={e => actStudio(v, e.target.value)}
                                                />
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div hidden={selected !== "8"}>
                        <div className="flex text-center">
                            <input type="text" value={searchEt} onChange={e => setSearchEt(e.target.value)} placeholder='Rechercher' className='p-1 w-1/2 border-b border-bleen-100'/>
                            <input type="button" value="Ajouter un état" className="w-1/2 bg-bleen-200 hover:bg-bleen-300 cursor-pointer" onClick={_ => setShowAddEt(!showAddEt)}/>
                        </div>
                        <div className="flex border-b border-bleen-100" style={{display: !showAddEt && 'none'}}>
                            <input type="text" value={newStateName} onChange={e => setNewStateName(e.target.value)} placeholder="Titre"  className='p-1 w-full'/>
                            <input type="button" value="Ajouter" onClick={_ => addState()} className="px-2 bg-bleen-200 hover:bg-bleen-300 cursor-pointer"/>
                        </div>
                        <div className="overflow-y-scroll max-h-[800px]">
                            <table className="table-auto w-full text-left">
                                <thead className="p-1">
                                    <tr className="border-b border-bleen-100">
                                        <th className="p-1">Nom</th>
                                    </tr>
                                </thead>
                                <tbody className="p-1">
                                    {Object.values(states).filter(f => new RegExp(searchEt, 'i').test(`${f.name}`)).map(v => 
                                        <tr className="border-b border-bleen-100" key={v.id}>
                                            <td className="p-1">
                                                <input 
                                                    type="text" 
                                                    defaultValue={v.name} 
                                                    className="p-1 w-full" 
                                                    onBlur={e => actState(v, e.target.value)}
                                                />
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    );
};

export default AAnime