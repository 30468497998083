import React from 'react';
import { NavLink } from 'react-router-dom';
import { datas } from '../utils/functions';

const SearchAnime = ({anime, className, move, setSearch}) => {
    return (
        <NavLink to={`/anime/${anime.cleanTitle}`} className={'h-14 my-1 flex items-center ' + className}  onClick={_ => setSearch("") & (move !== false && move(anime.cleanTitle))}>
            <img src={`${datas}/${anime.cleanTitle}/${anime.cleanTitle}.png`} alt={anime.title} className="w-auto h-14"/>
            <p className="pl-4 w-full">{anime.title}</p>
        </NavLink>
    );
};

export default SearchAnime;