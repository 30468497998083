import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie'
import { api} from '../../utils/functions';
import { useNavigate, useParams, NavLink } from 'react-router-dom';
import { Nav, NavButton } from '../../components/Nav';
const AUser = () => {
    const [cookie, , removeCookie] = useCookies(['session-token'])
    const [mount, setMount] = useState(false)
    const [selected, setSelected] = useState("1")
    const [role, setRole] = useState(4)
    const [isMe, setIsMe] = useState(false)
    const [roles, setRoles] = useState([])
    const [histories, setHistories] = useState([])
    const [scores, setScores] = useState([])
    const [favorites, setFavorites] = useState([])
    const [data, setData] = useState({})

    const charToData = {
        "e" : ["nbEp","Épisodes"],
        "f" : ["nbFl","Films"],
        "o" : ["nbOa","OAVs"]
    }

    let navigate = useNavigate()
    const { id } = useParams()
    
    useEffect(() => {
        if (!mount) {
            setMount(true)
            if (cookie['session-token'])
                axios.post(`${api}/users/check`, { token: cookie['session-token'] })
                    .then(res => {
                        if (!res.data.correct) {
                            navigate('/login', { replace: true })
                            removeCookie('session-token')
                        }
                        else if (res.data.correct.role > 3) {
                            navigate('/animes', { replace: true })
                        }
                        setIsMe(res.data.correct.role === 1)
                    })
            else
                navigate('/login', { replace: true })

            axios.get(`${api}/users/info?id=${id}`,{headers: {Autorization: cookie['session-token']}})
                .then(res => {
                    setData(res.data.data)
                    setRole(res.data.data.idRole)
                })
            axios.get(`${api}/roles/list`)
                .then(res => {
                    setRoles(res.data.roles)
                })
            axios.get(`${api}/histories/list`,{headers: {Autorization: cookie['session-token']}})
                .then(res => {
                    setHistories(res.data.histories)
                })
            axios.get(`${api}/favorites/list`,{headers: {Autorization: cookie['session-token']}})
                .then(res => {
                    setFavorites(res.data.favorites)
                })
            axios.get(`${api}/scores/list`,{headers: {Autorization: cookie['session-token']}})
                .then(res => {
                    setScores(res.data.scores)
                })
        }
    }, [mount, cookie, navigate, id, removeCookie])

    const actUser = async(type,v) => {
        const send = {
            id: id,
            role: type === "role" ? v : data.idRole,
            otk: type === "otk" ? v : data.ownReferral,
            verif: type === "verif" ? v : data.verify
        }
        await axios.put(`${api}/users/act`, send,{headers: {Autorization: cookie['session-token']}})
        await axios.get(`${api}/users/info?id=${id}`)
            .then(res => {
                setData(res.data.data)
                setRole(res.data.data.idRole)
            })
    }
    return (
        <div>
            <Nav>
                <NavLink to="/admin/users" className="cursor-pointer h-full"><NavButton className="mr-0.5">Administration</NavButton></NavLink>
            </Nav>
            <main className="grid laptop:grid-cols-3 phone:grid-cols-1 h-fall tablet:mb-0 tablet:mt-14 phone:mb-14 p-4 justify-center items-center">
                <div className="bg-bleen-100 col-span-1 h-full text-center">
                    {
                        ["1-l'utilisateur", "2-les historiques", "3-les favoris", "4-les notes"].map(b => 
                            <input 
                                id={b.split('-')[0]}
                                key={b.split('-')[0]}
                                type="button"
                                value={'Consulter ' + b.split('-')[1]} 
                                className="p-2 w-full bg-bleen-200 hover:bg-bleen-300 duration-300 cursor-pointer"
                                style={{backgroundColor: b.split('-')[0] === selected && '#8CDEAE'}}
                                onClick={e => setSelected(e.target.id)}
                            />
                        )
                    }
                    
                </div>
                <div className="flex flex-col laptop:col-span-2 phone:col-span-1 h-full bg-white relative">
                    <div hidden={selected !== "1"}>
                        <table className="table-auto w-full text-left">
                            <tbody className="p-1" >
                                <tr className="border-b border-bleen-100">
                                    <th className="p-1 tablet:w-40 phone:w-28">Pseudo :</th>
                                    <td className="p-1">
                                        <p type="text" className="w-full pl-2 p-1">{data.username}</p>
                                    </td>
                                </tr>
                                <tr className="border-b border-bleen-100">
                                    <th className="p-1">Rôle :</th>
                                    <td className="p-1">
                                        <select 
                                            type="text" 
                                            className="w-full bg-white p-1" 
                                            onChange={e => setRole(e.target.value) & actUser('role', e.target.value)}
                                            value={role}
                                            disabled={!isMe}
                                        >
                                            {Object.values(roles).map(r => <option key={r.id} value={r.id} disabled={r.id===1}>{r.name}</option>)}
                                        </select>
                                    </td>
                                </tr>
                                <tr className="border-b border-bleen-100">
                                    <th className="p-1">Email :</th>
                                    <td className="p-1">
                                        <p type="text" className="w-full pl-2 p-1">{data.email}</p>
                                    </td>
                                </tr>
                                <tr className="border-b border-bleen-100">
                                    <th className="p-1">Parrain :</th>
                                    <td className="p-1">
                                        <p type="text" className="w-full pl-2 p-1">{data.godfather}</p>
                                    </td>
                                </tr>
                                <tr className="border-b border-bleen-100">
                                    <th className="p-1">Code otaku :</th>
                                    <td className="p-1">
                                        <input 
                                            type="text" 
                                            defaultValue={data.ownReferral}
                                            className="w-full pl-2 p-1"
                                            onBlur={e => actUser('otk', e.target.value)}
                                        />
                                    </td>
                                </tr>
                                <tr className="border-b border-bleen-100">
                                    <th className="p-1">Vérifié ? :</th>
                                    <td className="p-1 pl-3">
                                        <label htmlFor="green-toggle" className="inline-flex relative items-center mr-5 cursor-pointer mt-2">
                                            <input type="checkbox" id="green-toggle" className="sr-only peer" defaultChecked={data.verify} onClick={e => actUser('verif', e.target.checked)}/>
                                            <div className="w-11 h-6 bg-gray-200 rounded-full peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-bleen-500"></div>
                                            <span className="ml-3">Non {'<->'} Oui</span>
                                        </label>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div hidden={selected !== "2"}>
                        <div className="overflow-y-scroll max-h-[820px]">
                            <table className="table-auto w-full text-left">
                                <thead className="p-1">
                                    <tr className="border-b border-bleen-100">
                                        <th className="p-1 tablet:w-52 phone:w-24">Anime</th>
                                        <th className="p-1">Position</th>
                                        <th className="p-1">Type</th>
                                        <th className="p-1">Date</th>
                                    </tr>
                                </thead>
                                <tbody className="p-1">
                                {Object.values(histories).filter(f => f.idUser === parseInt(id) && f.number !== f[charToData[f.type][0]]).map(v => 
                                        <tr className="border-b border-bleen-100" key={Math.random()}>
                                            <td className="p-1">
                                                <p type="text" className="p-1 w-full">
                                                    {v.title}
                                                </p>
                                            </td>
                                            <td className="p-1">
                                                <p type="text" className="p-1 w-full">
                                                    {v.number !== v[charToData[v.type][0]] ? `${v.number}/${v[charToData[v.type][0]]}` : "Terminé"} 
                                                </p>
                                            </td>
                                            <td className="p-1">
                                                <p type="text" className="p-1 w-full">
                                                    {charToData[v.type][1]} 
                                                </p>
                                            </td>
                                            <td className="p-1">
                                                <p type="text" className="p-1 w-full">
                                                    {v.updatedAt.split("T")[0].split("-").reverse().join('/')} 
                                                </p>
                                            </td>
                                        </tr>
                                    )}
                                    {Object.values(histories).filter(f => f.idUser === parseInt(id) && f.number === f[charToData[f.type][0]]).map(v => 
                                        <tr className="border-b border-bleen-100" key={Math.random()}>
                                            <td className="p-1">
                                                <p type="text" className="p-1 w-full">
                                                    {v.title}
                                                </p>
                                            </td>
                                            <td className="p-1">
                                                <p type="text" className="p-1 w-full">
                                                    {v.number !== v[charToData[v.type][0]] ? `${v.number}/${v[charToData[v.type][0]]}` : "Terminé"} 
                                                </p>
                                            </td>
                                            <td className="p-1">
                                                <p type="text" className="p-1 w-full">
                                                    {charToData[v.type][1]} 
                                                </p>
                                            </td>
                                            <td className="p-1">
                                                <p type="text" className="p-1 w-full">
                                                    {v.updatedAt.split("T")[0].split("-").reverse().join('/')} 
                                                </p>
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div hidden={selected !== "3"}>
                        <div className="overflow-y-scroll max-h-[800px]">
                            <table className="table-auto w-full text-left">
                                <thead className="p-1">
                                    <tr className="border-b border-bleen-100">
                                        <th className="p-1 tablet:w-52 phone:w-24">Anime</th>
                                        <th className="p-1">Date</th>
                                    </tr>
                                </thead>
                                <tbody className="p-1">
                                    {Object.values(favorites).filter(f => f.idUser === parseInt(id) ).map(v => 
                                        <tr className="border-b border-bleen-100" key={Math.random()}>
                                            <td className="p-1">
                                                <p type="text" className="p-1 w-full">
                                                    {v.title} 
                                                </p>
                                            </td>
                                            <td className="p-1">
                                                <p type="text" className="p-1 w-full">
                                                    {v.updatedAt.split("T")[0].split("-").reverse().join('/')} 
                                                </p>
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div hidden={selected !== "4"}>
                        <div className="overflow-y-scroll max-h-[800px]">
                            <table className="table-auto w-full text-left">
                                <thead className="p-1">
                                    <tr className="border-b border-bleen-100">
                                        <th className="p-1 tablet:w-52 phone:w-24">Anime</th>
                                        <th className="p-1">Total</th>
                                        <th className="p-1">Graphisme</th>
                                        <th className="p-1">Histoire</th>
                                        <th className="p-1">Personnage</th>
                                        <th className="p-1">Coeur</th>
                                        <th className="p-1">Date</th>
                                    </tr>
                                </thead>
                                <tbody className="p-1">
                                    {Object.values(scores).filter(s => s.id === parseInt(id)).map(v => 
                                        <tr className="border-b border-bleen-100" key={Math.random()}>
                                            <td className="p-1">
                                                <p type="text" className="p-1 w-full">{v.title}</p>
                                            </td>
                                            <td className="p-1">
                                                <p type="text" className="p-1 w-full">{v.beauty + v.history + v.character + v.heart}/20</p>
                                            </td>
                                            <td className="p-1">
                                                <p type="text" className="p-1 w-full">{v.beauty}</p>
                                            </td>
                                            <td className="p-1">
                                                <p type="text" className="p-1 w-full">{v.history}</p>
                                            </td>
                                            <td className="p-1">
                                                <p type="text" className="p-1 w-full">{v.character}</p>
                                            </td>
                                            <td className="p-1">
                                                <p type="text" className="p-1 w-full">{v.heart}</p>
                                            </td>
                                            <td className="p-1">
                                                <p type="text" className="p-1 w-full">
                                                    {v.updatedAt.split("T")[0].split("-").reverse().join('/')} 
                                                </p>
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    );
};

export default AUser