import React, { useState } from 'react';

const FieldInput = ({placeholder, type='text', name, onChange, maxlength=999}) => {

    const minus = type === 'password'

    const [eye, setEye] = useState(false)
    const [dType, setdType] = useState(type)

    const onClick = () => {
        setEye(!eye)
        setdType(dType === "text" ? "password" : "text")
    }

    return (
        <div className="flex input">
            <input className={`bg-bleen-100 p-1 pl-2 tablet:mb-5 phone:mb-2 border-b border-bleen-400 rounded-t ${minus ? 'desktop:w-[230px]':  'desktop:w-[260px]'} desktop:ml-[20px] ${minus ? 'tablet:w-[250px]':  'tablet:w-[280px]'} tablet:ml-[10px] ${minus ? 'phone:w-[270px]':  'phone:w-[300px]'} phone:ml-[20px]`} type={dType} placeholder={placeholder} id={name} maxLength={maxlength} onChange={(e) => onChange(e.target.value)} required/>
            {type === "password" &&
            <div className="">
                <img src="/images/svg/show.png" alt="show" className='h-6 m-1 cursor-pointer' onClick={onClick} style={{display: !eye ? 'none' : 'block'}}/>
                <img src="/images/svg/mask.png" alt="mask" className='h-6 m-1 cursor-pointer' onClick={onClick} style={{display: eye ? 'none' : 'block'}} />
            </div>
            }
        </div>
    );
};

export default FieldInput;