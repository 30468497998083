import axios from 'axios';
import React, { useEffect, useState, useRef } from 'react';
import { useCookies } from 'react-cookie'
import { api, datas } from '../../utils/functions';
import { useLocation, useNavigate, NavLink} from 'react-router-dom';
import {Nav} from '../../components/Nav';
import MultiSelector from '../../components/MultiSelector';
import NautiljonItem from '../../components/admin/NautiljonItem';
const Admin = () => {
    const [mount, setMount] = useState(false)
    const [openAddAnime, setOpenAddAnime] = useState(false)
    const [animes, setAnimes] = useState({})
    const [users, setUsers] = useState({})
    const [search, setSearch] = useState("")
    const [cookie,, removeCookie] = useCookies(['session-token'])
    const location = useLocation()

    const [titre, setTitre] = useState("")
    const [urlImages, setUrlImages] = useState([])
    const [selectedImageUrl, setSelectedImageUrl] = useState("")
    const [desc, setDesc] = useState("")
    const [url, setUrl] = useState("")
    const [year, setYear] = useState("");
    const [state, setState] = useState(1)
    const [newAliasName, setNewAliasName] = useState("")

    const [aAlias, setAAlias] = useState([])    
    const [aType, setAType] = useState([])
    const [tempType, setTempType] = useState([])
    const [aStudio, setAStudio] = useState([])
    const [tempStudio, setTempStudio] = useState([])

    const [states, setStates] = useState({})
    const [types, setTypes] = useState({})
    const [studios, setStudios] = useState({})

    const [nautiljonList, setNautiljonList] = useState([])
    const [nautiljonListPanel, setNautiljonListPanel] = useState("none")
    const [imageListPanel, setImageListPanel] = useState("none")

    const addTitre = useRef(null)

    let navigate = useNavigate()

    useEffect(() => {
        if(!mount) {
            setMount(true)
            if(cookie['session-token']) 
                axios.post(`${api}/users/check`, {token: cookie['session-token']})
                .then((res) => {
                    if(!res.data.correct){
                        navigate('/login', {replace: true})
                        removeCookie('session-token')
                    }
                    else if(res.data.correct.role > 3) {
                        navigate('/animes', {replace: true})
                    }
                })
            else
                navigate('/login', {replace: true})

            axios.get(api + '/animes/list?t=min', {headers: {Autorization: cookie['session-token']}})
                .then(res => setAnimes(res.data.animes))
            axios.get(api + '/users/list', {headers: {Autorization: cookie['session-token']}})
                .then(res => setUsers(res.data.users))
            axios.get(`${api}/states/list`)
            .then(res => setStates(res.data.states))
            axios.get(`${api}/types/list`)
                .then(res => setTypes(res.data.types))
            axios.get(`${api}/studios/list`)
                .then(res => setStudios(res.data.studios))
        }
        const interval = setInterval(_ => {
            if(tempType.length > 0) {
                for(let temp of tempType)
                    if(types.filter(s => s.name.toLowerCase() === temp.toLowerCase()).length === 1 && aType.filter(s => s.name.toLowerCase() === temp.toLowerCase()).length === 0) {
                        setAType(c => [...c, types.filter(s => s.name.toLowerCase() === temp.toLowerCase())[0]])
                        setTempType(c => c.filter(f => f !== temp))
                    }
            }
            if(tempStudio.length > 0) {
                for(let temp of tempStudio)
                    if(studios.filter(s => s.name.toLowerCase() === temp.toLowerCase()).length === 1 && aStudio.filter(s => s.name.toLowerCase() === temp.toLowerCase()).length === 0) {
                        setAType(c => [...c, studios.filter(s => s.name.toLowerCase() === temp.toLowerCase())[0]])
                        setTempStudio(c => c.filter(f => f !== temp))
                    }
            }
        }, 200)
        return () => clearInterval(interval)
    }, [mount, cookie, navigate, removeCookie, tempStudio, tempType, types, studios, aStudio, aType])

    const handleSubmit = async(e) => {
        e.preventDefault()        
        let {titre, file, year, state, isOnArk, desc} = e.target

        const data = {
            title: titre.value,
            clean: titre.value.replace(/ |:|-|\//g, ''),
            alias: aAlias,
            year: parseInt(year.value),
            state: parseInt(state.value),
            isOnArk: isOnArk.checked,
            studio: aStudio,
            type: aType,
            url: url,
            desc: desc.value
        }

        await axios.post(`${api}/anime`, data, {headers: {Autorization: cookie['session-token']}})
        if(selectedImageUrl)
            await axios.post(`${api}/download?name=${data.clean}`, {url:selectedImageUrl}, {headers: {Autorization: cookie['session-token']}})
        else {
            const image = new FormData()
            image.append(`file`, file.files[0])
            await axios.post(`${api}/upload?type=posters&name=${data.clean}`, image, {headers: {Autorization: cookie['session-token'], "Content-Type": "multipart/form-data"}})
            file.value = ""
        }
            
        await axios.get(api + '/animes/list?t=min', {headers: {Autorization: cookie['session-token']}})
            .then(res => setAnimes(res.data.animes))
        
        titre.value = desc.value = ""
        state.value = 1
        isOnArk.checked = false
        setAAlias([])
        setAStudio([])
        setAType([])
        setYear("")
        setOpenAddAnime(false)
    }

    const addStudio = e => setAStudio(c => [...c, e])
    const delStudio = e => setAStudio(c => c.filter(f => f !== e ))
    const addType = e => setAType(c => [...c, e])
    const delType = e => setAType(c => c.filter(f => f !== e ))

    const newType = async(name) => {
        const data = {
            name: name,
            isTheme: false
        }
        await axios.post(`${api}/animes/type`, data,{headers: {Autorization: cookie['session-token']}})
        await axios.get(`${api}/types/list`)
            .then(res => setTypes(res.data.types))
    }
    const newStudio = async(name) => {
        const data = {
            name: name
        }
        await axios.post(`${api}/animes/studio`, data,{headers: {Autorization: cookie['session-token']}})
        await axios.get(`${api}/studios/list`)
            .then(res => setStudios(res.data.studios))
    }

    const searchInNautiljonList = async() => {
        await axios.get(`${api}/animes/nautiljon/list?titre=${addTitre.current.value}&serie=0`,)
            .then(res => {
                setNautiljonList(res.data.info)
                if(res.data.info.length > 1)
                    setNautiljonListPanel("flex")
                else if (res.data.info.length === 1)
                    searchInNautiljon(res.data.info[0].url)
            })
    }
    const searchImage = async() => {
        await axios.get(`${api}/animes/searchImage?titre=${addTitre.current.value}`,)
            .then(res => {
                setUrlImages(res.data.urls)
                setImageListPanel("flex")
            })
    }

    const searchInNautiljon = async(url) => {
        setUrl(url)
        await axios.post(`${api}/animes/nautiljon`, {url: url})
            .then(async(res) => {
                setNautiljonList([])
                setNautiljonListPanel("none")
                let data = res.data.info
                setTitre(data.titre)
                setYear(data.annee)
                setDesc(data.description)
                setState(data.status)
                for(let alias of data.alias) 
                    setAAlias(c => [...c, alias])
                
                for(let studio of data.studios) {
                    if(studios.filter(s => s.name.toLowerCase() === studio.toLowerCase()).length === 0)
                        await newStudio(studio)
                    if(studios.filter(s => s.name.toLowerCase() === studio.toLowerCase()).length === 1)
                        setAStudio(c => [...c, studios.filter(s => s.name.toLowerCase() === studio.toLowerCase())[0]])
                    else 
                        setTempStudio(c => [...c, studio])
                }

                for(let genre of data.genres) {
                    if(types.filter(s => s.name.toLowerCase() === genre.toLowerCase()).length === 0)
                        await newType(genre)
                    if(types.filter(s => s.name.toLowerCase() === genre.toLowerCase()).length === 1)
                        setAType(c => [...c, types.filter(s => s.name.toLowerCase() === genre.toLowerCase())[0]])
                    else 
                        setTempType(c => [...c, genre])
                }
                    
            })
        await searchImage()
    }

    return (
        <div>
            <Nav></Nav>
            <main className="flex flex-col justify-center items-center px-4 tablet:pt-20 phone:pt-4 tablet:pb-4 phone:pb-16">
               <div className="flex justify-center w-full p-1 mb-2 bg-white">
                    <NavLink to='/admin/animes' className="bg-bleen-300 hover:bg-bleen-500 w-full p-2 text-xl m-1 cursor-pointer text-center">Animes</NavLink>
                    <NavLink to='/admin/users' className="bg-bleen-300 hover:bg-bleen-500 w-full p-2 text-xl m-1 cursor-pointer text-center">Utilisateurs</NavLink>
               </div>
               <div style={{display: nautiljonListPanel}} className="absolute flex flex-wrap phone:justify-center tablet:justify-between p-2 bg-white z-100 top-64 border-2 border-bleen-200 rounded">{
                    nautiljonList.map(e => <NautiljonItem key={Math.random()} onClick={searchInNautiljon}>{e}</NautiljonItem>)
                }</div>
                <div style={{display: imageListPanel}} className="absolute flex flex-wrap phone:justify-center tablet:justify-between p-2 bg-white z-100 top-64 border-2 max-w-3xl border-bleen-200 rounded">
                    <img src="/images/svg/close.svg" alt="cross" className='w-5 cursor-pointer' onClick={_ => setImageListPanel('none')} />
                    {
                    urlImages.map(e => <img key={Math.random()} alt="posterFromBing" width={1280} height={720} src={e} className='w-40 m-2 cursor-pointer' onClick={_ => setSelectedImageUrl(e) & setImageListPanel('none')} onError={e => e.target.style.display = 'none'}/>)
                }</div>
               <div className="flex flex-col p-1 bg-white w-full">
                    <div className="flex">
                        <input type="search" className="border border-bleen-300 p-2 mb-2 w-full" placeholder="Rechercher" value={search} onChange={e => setSearch(e.target.value)} />   
                        { /\/admin\/animes/.test(location.pathname) && <button onClick={_ => setOpenAddAnime(!openAddAnime)} className="bg-bleen-300 hover:bg-bleen-500 ml-1 cursor-pointer text-center h-fit p-2 pb-1.5 text-xl w-1/3">Ajouter</button>}
                    </div>
                    {
                        /\/admin\/animes/.test(location.pathname) && <>
                        {
                            openAddAnime &&
                            <form onSubmit={handleSubmit} className="p-1 border border-bleen-200">
                                <table className="table-auto w-full text-left">
                                    <tbody className="p-1" >
                                        <tr className="border-b border-bleen-100">
                                            <th className="p-1 tablet:w-40 phone:w-24">Titre :</th>
                                            <td className="p-1">
                                                <div className="flex">
                                                    <input 
                                                        type="text" 
                                                        name="titre"
                                                        className="w-full pl-2 p-1 mr-2"
                                                        value={titre}
                                                        required
                                                        ref={addTitre}
                                                        onChange={e => setTitre(e.target.value)}
                                                    />
                                                    <input type="button"  onClick={searchInNautiljonList} className="px-2 bg-bleen-200 hover:bg-bleen-300 cursor-pointer" value="Rechercher"/>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr className="border-b border-bleen-100">
                                            <th className="p-1">Image:</th>
                                            <td className="p-1 flex">
                                                {selectedImageUrl ?
                                                    <p className='ml-2'>{selectedImageUrl}</p>
                                                :
                                                    <>
                                                        <label htmlFor="posters" className="ml-2">Ajouter l'image</label>
                                                        <input 
                                                            type="file" 
                                                            name="file"
                                                            className="w-full file:h-full cursor-pointer border border-bleen-100 rounded-l-full file:bg-bleen-200 file:hover:bg-bleen-300 file:border-none file:p-2 file:rounded-l-full file:text-bleen-800" 
                                                            accept='.png,.jpg'
                                                            required
                                                        />
                                                    </>
                                                }
                                            </td>
                                        </tr>
                                        <tr className="border-b border-bleen-100">
                                            <th className="p-1">Alias :</th>
                                            <td className="p-1">
                                                <div className="flex">
                                                    <input type="text" value={newAliasName} placeholder="Nouvel alias" onChange={e => setNewAliasName(e.target.value)} className="pl-2 w-full my-1"></input>
                                                    <input type="button" value="Ajouter" onClick={_ => setAAlias(c =>  [...c, newAliasName]) & setNewAliasName('')} className="px-2 bg-bleen-200 hover:bg-bleen-300 cursor-pointer"/>
                                                </div>
                                                {aAlias.map(v =>
                                                    <div className='flex' key={Math.random()} >
                                                        <p className="pl-2 w-full my-1">{v}</p> 
                                                        <img src="/images/svg/cross.svg" alt="cross" className='w-5 cursor-pointer' onClick={_ => setAAlias(c => c.filter(f => f !== v ))} />
                                                    </div>
                                                )}
                                            </td>
                                        </tr>
                                        <tr className="border-b border-bleen-100">
                                            <th className="p-1 tablet:w-40 phone:w-24">Année :</th>
                                            <td className="p-1">
                                                <input 
                                                    type="text"
                                                    value={year}
                                                    onChange={e => /^\d{0,4}$/.test(e.target.value) && setYear(e.target.value)}
                                                    name="year"
                                                    required
                                                    className="w-full pl-2 p-1" 
                                                />
                                            </td>
                                        </tr>
                                        <tr className="border-b border-bleen-100">
                                            <th className="p-1">Statuts :</th>
                                            <td className="p-1">
                                                <select 
                                                    type="text" 
                                                    className="w-full bg-white p-1 ml-1" 
                                                    name="state"
                                                    onChange={e => setState(e.target.value)}
                                                    value={state}
                                                >
                                                    {Object.values(states).map(s => <option key={s.id} value={s.id}>{s.name}</option>)}
                                                </select>
                                                    
                                            </td>
                                        </tr>
                                        <tr className="border-b border-bleen-100">
                                            <th className="p-1">Studio(s) :</th>
                                            <td className="p-1 pl-3">
                                                <MultiSelector data={studios} selected={aStudio} del={delStudio} add={addStudio}  _new={newStudio}></MultiSelector>
                                            </td>
                                        </tr>
                                        <tr className="border-b border-bleen-100">
                                            <th className="p-1">Genres :</th>
                                            <td className="p-1 pl-3">
                                                <MultiSelector data={types} selected={aType} del={delType} add={addType} _new={newType}></MultiSelector>
                                            </td>
                                        </tr>
                                        <tr className="border-b border-bleen-100">
                                            <th className="p-1">Synopsis :</th>
                                            <td className="p-1">
                                                <textarea  
                                                    className="w-full pl-1 p-1 border border-bleen-100 ml-2"
                                                    name="desc"
                                                    value={desc}
                                                    onChange={e => setDesc(e.target.value)}
                                                    spellCheck={false}
                                                    rows="8"
                                                />
                                            </td>
                                        </tr>
                                        <tr className="border-b border-bleen-100">
                                            <th className="p-1">Saison en arc ? :</th>
                                            <td className="p-1 pl-3">
                                                <label htmlFor="green-toggle" className="inline-flex relative items-center mr-5 cursor-pointer mt-2">
                                                    <input type="checkbox" id="green-toggle" className="sr-only peer" name="isOnArk"/>
                                                    <div className="w-11 h-6 bg-gray-200 rounded-full peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-bleen-500"></div>
                                                    <span className="ml-3">Non {'<->'} Oui</span>
                                                </label>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <input type="submit" className="bg-bleen-200 hover:bg-bleen-300 cursor-pointer p-2 w-full" value="Ajouter l'animé" />
                            </form>
                        }
                        <table className="table-auto">
                            <thead>
                                <tr className='text-left'>
                                    <th className="p-1">Images</th>
                                    <th className="p-1">Titre</th>
                                    <th className="p-1">Épisodes</th>
                                    <th className="p-1">Films</th>
                                    <th className="p-1">OAVs</th>
                                    <th className="p-1">GO</th>
                                </tr>
                            </thead>
                            <tbody className='border-b border-bleen-600'>
                                {Object.values(animes).filter(a => new RegExp(search !== "" ? search.replace(/ |:|-|\//g, '') : "", 'i').test(`${a.cleanTitle}${a.aliases.replace(/ |,|:|\//g, '')}`) & a.idState > 1).map(anime => 
                                    <tr key={Math.random()}>
                                        <td className="p-1">
                                            <img src={`${datas}/${anime.cleanTitle}/${anime.cleanTitle}.png`} alt={`${anime.cleanTitle}.png`} className="w-24"/>
                                        </td>
                                        <td className="p-1">{anime.title}</td>
                                        <td className="p-1">{anime.nbEp}</td>
                                        <td className="p-1">{anime.nbFl}</td>
                                        <td className="p-1">{anime.nbOa}</td>
                                        <td className="p-1"><NavLink to={`/admin/animes/${anime.cleanTitle}`}><img src="/images/svg/next.svg" alt="Go" className="w-10"/></NavLink></td>
                                    </tr>
                                )}
                            </tbody>
                            <tbody>
                                {Object.values(animes).filter(a => new RegExp(search !== "" ? search.replace(/ |:|-|\//g, '') : "", 'i').test(`${a.cleanTitle}${a.aliases.replace(/ |,|:|\//g, '')}`) & a.idState === 1).map(anime => 
                                    <tr key={Math.random()}>
                                        <td className="p-1">
                                            <img src={`${datas}/${anime.cleanTitle}/${anime.cleanTitle}.png`} alt={`${anime.cleanTitle}.png`} className="w-24"/>
                                        </td>
                                        <td className="p-1">{anime.title}</td>
                                        <td className="p-1">{anime.nbEp}</td>
                                        <td className="p-1">{anime.nbFl}</td>
                                        <td className="p-1">{anime.nbOa}</td>
                                        <td className="p-1"><NavLink to={`/admin/animes/${anime.cleanTitle}`}><img src="/images/svg/next.svg" alt="Go" className="w-10"/></NavLink></td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </>}
                    {
                        /\/admin\/users/.test(location.pathname) && 
                        <table className="table-auto">
                            <thead>
                                <tr className='text-left'>
                                    <th className="p-1">Pseudo</th>
                                    <th className="p-1">Role</th>
                                    <th className="p-1">Vérifié</th>
                                    <th className="p-1">GO</th>
                                </tr>
                            </thead>
                            <tbody>
                                {Object.values(users).filter(u => new RegExp(search, 'i').test(u.username)).map(user => 
                                    <tr key={Math.random()}>
                                        <td className="p-1">{user.username}</td>
                                        <td className="p-1">{user.role}</td>
                                        <td className="p-1">{user.verify ? 'OUI' : 'NON'}</td>
                                        <td className="p-1"><NavLink to={`/admin/users/${user.id}`}><img src="/images/svg/next.svg" alt="Go" className="w-10"/></NavLink></td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    }
               </div>
            </main>
        </div>
    );
};

export default Admin