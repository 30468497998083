import config from './config.json'

const isNotMobile = () => {
    return window.innerWidth >= 668
}

const domaine = `${config.domaine.protocol}://${config.domaine.host}${config.domaine.port !== 443 ? `:${config.domaine.port}` : ''}`
const datas = `${config.videos.protocol}://${config.videos.host}${config.videos.port !== 443 ? `:${config.videos.port}` : ''}`
const api = `${config.api.protocol}://${config.api.host}${config.api.port !== 443 ? `:${config.api.port}` : ''}`
const ws = `${config.ws.protocol}://${config.ws.host}${config.ws.port !== 443 ? `:${config.ws.port}` : ''}/ws`

export {
    isNotMobile,
    api, domaine, datas, ws
}