import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import { useCookies } from 'react-cookie'
import { api, datas } from '../utils/functions';
import { Link, NavLink, useNavigate, useParams, useLocation } from 'react-router-dom';
import {Nav, NavButton} from '../components/Nav';
import FieldText from '../components/FieldText';
import Saison from '../components/Saison';
import Video from '../components/Video';
import ReactPlayer from 'react-player';
import Scores from '../components/Scores';
import Report from '../components/Report';
import FlipCard from '../components/FlipCard';

const typeToDir = {
    'e' : 'series',
    'f' : 'movies',
    'o' : 'oavs'
}

const getFullNumber = (number=0) => {
    let size = number.toString().length;
    let toReturn = number.toString()
    for(let x = 0; x < 4-size; x++) {toReturn = '0' + toReturn}
    return toReturn
}

const ScoreMin = ({score, name}) => {
    return (
        <div className='row-span-1 col-span-1'>
            <div className='relative w-full h-full flex justify-center items-center text-center'>
                <svg className="w-[40px] h-[50px] -mt-6 absolute">
                    <circle cx="20" cy="20" r="17" fill='none' strokeWidth='3' stroke="#50CC83" strokeDashoffset={`${107-(score!== "Non noté" ? score : 0)/20*107}`} strokeDasharray='107' className='duration-500 -rotate-90 translate-y-full'/>
                </svg>
                <div className='mt-2 flex flex-col'>
                    <span className="text-xl lining-nums">{score}</span>
                    <span className="text-sm lining-nums">{name}</span>
                </div>
            </div>
        </div>
    )
}

const Anime = () => {
    const [actual, setActual] = useState({})
    const [videos, setVideos] = useState({})
    const [anime, setAnime] = useState({})
    const [scores, setScores] = useState({})
    const [saisons, setSaisons] = useState({})
    const [mount, setMount] = useState(false)
    const [cookie,, removeCookie] = useCookies(['session-token'])
    const [toHistory, setToHistory] = useState(0)
    const [favText, setFavText] = useState("")
    const [scoText, setScoText] = useState("")
    const [token, setToken] = useState({})
    const [selectedSee, setSelectedSee] = useState("")
    const [openScoreModal, setOpenScoreModal] = useState(false)
    const [openReportModal, setOpenReportModal] = useState(false)
    const {title, number, type} = useParams()
    const [timeCheck, setTimeCheck] = useState(false)
    const [commentList, setCommentList] = useState([])

    let video = useRef(null)

    const [videoData, setVideoData] = useState({currentTime: 0, play: false})

    let navigate = useNavigate()

    const location = useLocation()

    useEffect(() => {
        if(!mount) {
            setMount(true)
            if(cookie['session-token']) 
                axios.post(`${api}/users/check`, {token: cookie['session-token']})
                .then((res) => {
                    if(!res.data.correct){
                        navigate('/login', {replace: true})
                        removeCookie('session-token')
                    }
                    else {
                        setToken(res.data.correct)
                    }
                })
            else
                navigate('/login', {replace: true})

            axios.get(`${api}/animes/videos?title=${title}`, {headers: {Autorization: cookie['session-token']}})
            .then((res) => {
                setVideos(res.data.videos)
                setAnime(res.data.anime)
                setSaisons(res.data.saisons)
                setFavText(res.data.anime.isFav === null ? "Ajouter favoris" : "Supprimer favoris")
                if(number) {
                    switch(type) {
                        case "e" : 
                            let [aEpisode] = Object.values(res.data.videos).filter(f => f.type === "e").filter(e => e.number === parseInt(number))
                            if(res.data.saisons[0]) {
                                let [aSaison] = Object.values(res.data.saisons).filter(s => s.start <= aEpisode.number && (s.end >= aEpisode.number || s.end === null))
                                setActual({type: type, titre: aEpisode.title, numero: aEpisode.number, saison: aSaison.title, debut: aSaison.start, url: `${datas}/${title}/${typeToDir[type]}/${title}-${getFullNumber(aEpisode.number)}.mp4`})
                            }
                            else setActual({type: type, titre: aEpisode.title, numero: aEpisode.number, url: `${datas}/${title}/${typeToDir[type]}/${title}-${getFullNumber(aEpisode.number)}.mp4`})
                            break
                        case "f" :
                            let [aFilm] = Object.values(res.data.videos).filter(f => f.type === "m").filter(f => f.number === parseInt(number))
                            setActual({type: type, titre: aFilm.title, numero: aFilm.number, url: `${datas}/${title}/${typeToDir[type]}/${title}-${getFullNumber(aFilm.number)}.mp4`})
                            break
                        case "o" :
                            let [aOav] = Object.values(res.data.videos).filter(f => f.type === "o").filter(o => o.number === parseInt(number))
                            setActual({type: type, titre: aOav.title, numero: aOav.number, url: `${datas}/${title}/${typeToDir[type]}/${title}-${getFullNumber(aOav.number)}.mp4`})
                            break
                        default:
                            break
                    }
                }
            })
            .catch(_ => {navigate('/animes', {replace: true})})

            axios.get(`${api}/users/scores?title=${title}`, {headers: {Autorization: cookie['session-token']}})
                .then(res => {
                    setScores(res.data.scores)
                    setScoText(res.data.scores.nope ? "Ajouter note" : "Modifier note")
                })

            axios.get(`${api}/anime/comment?title=${title}`, {headers: {Autorization: cookie['session-token']}})
                .then(res => setCommentList(res.data.comments))
            
            if(!number)
                axios.get(`${api}/users/histories/number?title=${title}`, {headers: {Autorization: cookie['session-token']}})
                    .then(res => setToHistory(res.data.number))
        }
    }, [mount, saisons, videos, cookie, navigate, number, removeCookie, title, type])


    const reActual = async(nb, typ, titre = title, vid = videos, sai = saisons) => {
        switch(typ) {
            case 'e' :
                let [aEpisode] = Object.values(vid).filter(f => f.type === "e").filter(e => e.number === nb)
                if(sai[0]) {
                    let [aSaison] = Object.values(sai).filter(s => s.start <= aEpisode.number && (s.end >= aEpisode.number || s.end === null))
                    setActual({type: typ, titre: aEpisode.title, numero: aEpisode.number, saison: aSaison.title, debut: aSaison.start, url: `${datas}/${titre}/${typeToDir[typ]}/${titre}-${getFullNumber(aEpisode.number)}.mp4`})
                }
                else setActual({type: typ, titre: aEpisode.title, numero: aEpisode.number, url: `${datas}/${titre}/${typeToDir[typ]}/${titre}-${getFullNumber(aEpisode.number)}.mp4`})
                break;
            case "f" :
                let [aFilm] = Object.values(vid).filter(f => f.type === "m").filter(f => f.number === nb)
                setActual({type: typ, titre: aFilm.title, numero: aFilm.number, url: `${datas}/${titre}/${typeToDir[typ]}/${titre}-${getFullNumber(aFilm.number)}.mp4`})
                break;
            case "o" :
                let [aOav] = Object.values(vid).filter(f => f.type === "o").filter(o => o.number === nb)
                setActual({type: typ, titre: aOav.title, numero: aOav.number, url: `${datas}/${titre}/${typeToDir[typ]}/${titre}-${getFullNumber(aOav.number)}.mp4`})
                break;
            default:
                break;
        }
        const data = {
            title: titre, 
            number: nb,
            time: 0,
            type: typ
        }
        axios.put(`${api}/users/histories/time`, data, {headers: {Autorization: cookie['session-token']}})
    }

    const reAnime = async(tit) => {
        return await axios.get(`${api}/animes/videos?title=${tit}`, {headers: {Autorization: cookie['session-token']}})
            .then((res) => {
                setVideos(res.data.videos)
                setAnime(res.data.anime)
                setSaisons(res.data.saisons)
                setFavText(res.data.anime.isFav === null ? "Ajouter aux favoris" : "Supprimer des favoris")
                return [res.data.videos, res.data.saisons]
            })
            .catch((err) => {navigate('/animes', {replace: true})})
    }

    const handleFavClick = _ => {
        if(favText ===  "Supprimer favoris") {
            axios.delete(`${api}/users/favorites?anime=${title}`, {headers: {Autorization: cookie['session-token']}})
            setFavText("Ajouter favoris")
        }
        else {
            axios.post(`${api}/users/favorites?anime=${title}`,{}, {headers: {Autorization: cookie['session-token']}})
            setFavText("Supprimer favoris")
        }
    }

    const handleScoClick = async(beauty, history, character, heart, comment) => {
        await axios.post(`${api}/users/scores?title=${title}`,{beauty: beauty, history: history, character: character, heart: heart, comment:comment}, {headers: {Autorization: cookie['session-token']}})
        setScores({beauty: beauty, history: history, character: character, heart: heart, comment:comment})
        setScoText("Modifier note")
        setOpenScoreModal(false)
        await axios.get(`${api}/animes/videos?title=${title}`, {headers: {Autorization: cookie['session-token']}})
        .then((res) => {
            setAnime(res.data.anime)
        })
        .catch((err) => {navigate('/animes', {replace: true})})

        await axios.get(`${api}/anime/comment?title=${title}`, {headers: {Autorization: cookie['session-token']}})
        .then(res => setCommentList(res.data.comments))
    }




    const [playing, setPlaying] = useState(false)
    const [lastParam, setLastParam] = useState({})
    const [onStream, setOnStream] = useState(false)

    useEffect(_ => {
        const interval = setInterval(_ => {
            if(onStream && /^\/anime\/[A-Za-z0-9∞!ä.]{2,75}\/[efo]\/\d{1,4}$/.test(location.pathname) && lastParam.currentTime !== undefined) {
                if(playing !== lastParam.play){
                    setPlaying(lastParam.play)
                    video.current.seekTo(lastParam.currentTime, 'seconds')
                }
                const diffTemp = video.current.getCurrentTime() - lastParam.currentTime
                if(diffTemp > 4 || diffTemp < -4)
                    video.current.seekTo(lastParam.currentTime, 'seconds')
            }
        }, 1000)
        return _ => clearInterval(interval)
    }, [playing, lastParam, onStream, location])

    const updateVideo = param => {
        if(onStream) {
            if(playing !== param.play)
                setPlaying(param.play)
            const diffTemp = video.current.getCurrentTime() - param.currentTime
            if(diffTemp > 1 || diffTemp < -1)
                video.current.seekTo(param.currentTime, 'seconds')
            setLastParam(param)
        }
    }

    const handleProgress = e => {
        const data = {
            title: title, 
            number: parseInt(number),
            time: Math.round(e.playedSeconds),
            type: actual.type
        }
        if(data.time !== 0)
            axios.put(`${api}/users/histories/time`, data, {headers: {Autorization: cookie['session-token']}})
        if(onStream) return;
            setVideoData({currentTime: e.playedSeconds,  play: video.current.player.isPlaying, clean: title, title:anime.title, actual: {...actual, seasonByArc: anime.seasonByArc}})
    }

    const handlePlay = _ => {
        if(onStream) setPlaying(true)
        else setVideoData({currentTime: video.current.getCurrentTime(), play: true, clean: title, title:anime.title, actual: {...actual, seasonByArc: anime.seasonByArc}})
    }

    const handlePause = _ => {
        if(onStream) setPlaying(false)
        else setVideoData({currentTime: video.current.getCurrentTime(), play: false, clean: title, title:anime.title, actual: {...actual, seasonByArc: anime.seasonByArc}})
    }

    const handleLoaded = _ => {
        if(!timeCheck) {
            axios.get(`${api}/users/histories/time?title=${title}&number=${number}&type=${type}`, {headers: {Autorization: cookie['session-token']}})
                .then(res => video.current.seekTo(res.data.time, 'seconds'))
            setTimeCheck(true)
        }
        if(onStream) return;
        setVideoData({currentTime: video.current.getCurrentTime(), play: playing, clean: title, title:anime.title, actual: {...actual, seasonByArc: anime.seasonByArc}})
    }



    return (
        
        <div>
            <Nav move={reAnime} video={videoData} functions={{loaded: true,reAnime, reActual, setOnStream, updateVideo, video: video}}>
                { token.role <= 3 && <NavLink to={`/admin/animes/${title}`} className="cursor-pointer h-full"><NavButton className="mr-0.5">Administration</NavButton></NavLink>}
                <div onClick={handleFavClick}><NavButton className="tablet:mr-0.5">{favText}</NavButton></div>
                <div onClick={_ => setOpenScoreModal(true)}><NavButton>{scoText}</NavButton></div>
            </Nav>
            <Scores isOpen={openScoreModal} scores={scores} onClick={handleScoClick} setIsOpen={setOpenScoreModal}/>
            <Report isOpen={openReportModal} setIsOpen={setOpenReportModal} actual={actual}/>
            <main className="flex flex-col justify-center items-center px-4 tablet:pt-20 phone:pt-4 tablet:pb-4 phone:pb-16">
                {
                    !number 
                    ?   <div className="w-full tablet:flex tablet:justify-center tablet:items-center">
                            <div className="tablet:px-10 tablet:absolute flex tablet:justify-between phone:justify-around items-center w-full">
                                <span></span>
                                <Link onClick={_ => reActual(toHistory !== anime.nbEp ? toHistory : 1, anime.nbEp > 0 ? "e" : "f")} to={`/anime/${title}/${anime.nbEp > 0 ? "e" : "f"}/${toHistory !== anime.nbEp ? toHistory : 1}`}><img className='w-14' src="/images/svg/next.svg" alt="next"/></Link>
                            </div>
                            <div className='tablet:w-2/3 z-10'>
                                <img src={`${datas}/${title}/${title}.png`} alt={title}/>
                            </div>
                        </div>
                    :   <div className="w-full tablet:flex tablet:justify-center tablet:items-center">
                            <div className="tablet:px-10 tablet:absolute flex tablet:justify-between phone:justify-around items-center w-full" style={{display: onStream ? "none" : "flex"}}>
                                <Link 
                                    to={`/anime/${title}/${actual.type}/${parseInt(number)-1}`} 
                                    onClick={_ => reActual(parseInt(number)-1, actual.type)}
                                    style={{visibility: parseInt(actual.numero) === 1 ? 'hidden' : 'visible'}}
                                >
                                    <img className='w-14 cursor-pointer' src="/images/svg/previous.svg" alt="previous"/>
                                </Link>
                                <Link 
                                    to={`/anime/${title}/${actual.type}/${parseInt(number)+1}`} 
                                    onClick={_ => reActual(parseInt(number)+1, actual.type)}
                                    style={{visibility: parseInt(actual.numero) === (actual.type === "e" ? anime.nbEp : (actual.type === "f" ? anime.nbFl : anime.nbOa)) ? 'hidden' : 'visible'}} 
                                >
                                    <img className='w-14 cursor-pointer' src="/images/svg/next.svg" alt="next"/>
                                </Link>
                            </div>
                            <div className='tablet:w-2/3 z-10'>
                                <h1 className='text-center mb-2 text-2xl'><font className='text-3xl font-medium'>{actual.saison ? `${actual.saison} - ` :  ''}[{actual.type === "e" ? `Épisode` : (actual.type === "f" ? "Film" : "OAV")} {actual.saison ? (anime.seasonByArc ? actual.numero : actual.numero-actual.debut+1 ) : actual.numero}]</font><br />{actual.titre}</h1>
                                <ReactPlayer 
                                    ref={video}
                                    playing={playing}
                                    controls
                                    url={actual.url} 
                                    width="100%" 
                                    height="100%"
                                    id="video"
                                    progressInterval={2000}
                                    onProgress={handleProgress}
                                    onPlay={handlePlay}
                                    onPause={handlePause}
                                    onReady={handleLoaded}
                                />
                                <button className='text-red-500 hover:text-red-700 duration-300 mb-2' onClick={_ => setOpenReportModal(true)}>Signaler</button>
                            </div>
                        </div>
                }
                <div className= "bg-bleen-100 w-full mt-2 grid phone:grid-cols-4 tablet:grid-cols-6 gap-2 p-2">
                    <div className="bg-white col-span-4 flex flex-col items-center justify-center text-center p-2">
                        <p className="font-medium text-3xl uppercase">{anime.title}</p>
                        <p className="uppercase">{anime.aliases}</p>
                    </div>
                    <div className="bg-white col-span-2 row-span-2 p-2">
                       <FlipCard>
                            <div className="w-full h-full absolute grid grid-cols-2 grid-rows-2" style={{backfaceVisibility: "hidden", transform: "rotateY(180deg)"}}>
                                <ScoreMin name="Graphisme" score={anime.beauty}/>
                                <ScoreMin name="Histoire" score={anime.history}/>
                                <ScoreMin name="Personnage" score={anime.character}/>
                                <ScoreMin name="Coeur" score={anime.heart}/>
                            </div>
                            <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2" style={{backfaceVisibility: "hidden"}}>
                                <svg className="w-[100px] h-[100px] mx-auto">
                                    <circle cx="50" cy="50" r="46" fill='none' strokeWidth='8' stroke="#50CC83" strokeDashoffset={`${289-(anime.score !== "Non noté" ? anime.score : 0)/20*289}`} strokeDasharray='289' className='duration-500 -rotate-90 translate-y-full'></circle>
                                </svg>
                                <span className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-3xl lining-nums">{anime.score}</span>
                            </div>
                       </FlipCard>
                    </div>
                    <div className="bg-white col-span-2 flex items-center justify-center text-center p-2">
                        {anime.nbEp > 0 ? <p className=""><font className="tablet:text-3xl phone:text-xl">{anime.nbEp}</font><br /> épisode{anime.nbEp > 1 ?'s':''}</p> : ''}
                        {anime.nbEp > 0 && (anime.nbFl > 0 || anime.nbOa > 0)  ? <p className="tablet:mx-4 phone:mx-2"> </p> : ''}
                        {anime.nbFl > 0 ? <p className=""><font className="tablet:text-3xl phone:text-xl">{anime.nbFl}</font><br /> film{anime.nbFl > 1 ?'s':''}</p> : ''}
                        {anime.nbEp > 0 && anime.nbFl > 0 && anime.nbOa > 0  ? <p className="tablet:mx-4 phone:mx-2"> </p> : ''}
                        {anime.nbOa > 0 ? <p className=""><font className="tablet:text-3xl phone:text-xl">{anime.nbOa}</font><br /> OAV{anime.nbOa > 1 ?'s':''}</p> : ''}
                    </div>
                    <div className="bg-white col-span-2 flex items-center justify-center text-center p-2 ">
                    <p className="text-3xl">{anime.year}</p>
                    </div>
                    <div className="bg-white phone:col-span-4 tablet:col-span-6 flex flex-wrap justify-around text-center p-2 ">
                        {anime.theme && anime.theme.split(', ').length > 0 && <p className="mr-2">{anime.theme}</p>}
                        <p className="">{anime.types}</p>
                    </div>
                    <div className="bg-white phone:col-span-2 tablet:col-span-3 flex items-center justify-center text-center p-2 ">
                        <p className="">{anime.studio}</p>
                    </div>
                    <div className="bg-white phone:col-span-2 tablet:col-span-3 flex items-center justify-center text-center p-2 ">
                        <p className="">{anime.state}</p>
                    </div>
                    <div className="bg-white phone:col-span-4 tablet:col-span-6 p-2 text-justify ">
                        <FieldText>{anime.description}</FieldText>
                    </div>
                </div>
                <div className='w-full mt-4'>
                    <div className='w-full flex bg-bleen-100 p-2'>
                        <button className='w-full bg-white p-1 mr-1 hover:bg-gray-50' onClick={_ => setSelectedSee(c => c === "e" ? "" : "e")} style={{color: selectedSee === "e" ? "rgb(52,178,104)" : "" , display: anime.nbEp > 0 ? "" : "none" }}>Voir les épisodes</button>
                        <button className='w-full bg-white p-1 hover:bg-gray-50' onClick={_ => setSelectedSee(c => c === "f" ? "" : "f")} style={{color: selectedSee === "f" ? "rgb(52,178,104)" : "" , display: anime.nbFl > 0 ? "" : "none" }}>Voir les films</button>
                        <button className='w-full bg-white p-1 ml-1 hover:bg-gray-50' onClick={_ => setSelectedSee(c => c === "o" ? "" : "o")} style={{color: selectedSee === "o" ? "rgb(52,178,104)" : "" , display: anime.nbOa > 0 ? "" : "none" }}>Voir les OAVs</button>
                    </div>
                    <div hidden={selectedSee !== "e"}>{
                        saisons[0] 
                        ?   <div className="grid grid-cols-1 gap-2 bg-bleen-100 w-full p-2">
                                {Object.values(saisons).map(saison => <Saison actual={actual} saison={saison} nbEp={anime.nbEp} key={saison.number}>{Object.values(videos).filter(f => f.type === "e").filter((episode) => (episode.number <= saison.end && episode.number >= saison.start) || (saison.end === null  && episode.number >= saison.start) ).map(episode => <Video type="e" actual={actual} onStream={onStream} reActual={reActual} saison={saison} video={episode} anime={anime} key={episode.number}/>)}</Saison>)}
                            </div>
                        :   <div className="flex flex-col bg-bleen-100 w-full p-2">
                                {Object.values(videos).filter(f => f.type === "e").map(episode => <Video type="e" reActual={reActual} actual={actual} video={episode} anime={anime} key={episode.number}/>)}
                            </div>
                    }</div>
                    <div hidden={selectedSee !== "f"}>
                        <div className="flex flex-col bg-bleen-100 w-full p-2">
                            {Object.values(videos).filter(f => f.type === "m").map(film => <Video type="f" reActual={reActual} actual={actual} onStream={onStream} video={film} anime={anime} key={film.number}/>)}
                        </div>
                    </div>
                    <div hidden={selectedSee !== "o"}>
                        <div className="flex flex-col bg-bleen-100 w-full p-2">
                            {Object.values(videos).filter(f => f.type === "o").map(oav => <Video type="o" reActual={reActual} actual={actual} onStream={onStream} video={oav} anime={anime} key={oav.number}/>)}
                        </div>
                    </div>
                </div>
                <div className="w-full mt-4">
                    <div className="w-full flex flex-col bg-bleen-100 p-2">
                        <div className="bg-white">
                            {Object.values(commentList).filter(f => f.comment !== null && f.comment !== "").map(c => 
                                <div className='w-fit p-2' key={Math.random()}>
                                    <div className='bg-bleen-200 p-2 rounded-lg'>
                                        <p className='pb-2'>{c.comment}</p>
                                        <div className="w-full h-full flex justify-around">
                                            <ScoreMin name="Graphisme" score={c.beauty*4}/>
                                            <ScoreMin name="Histoire" score={c.history*4}/>
                                            <ScoreMin name="Personnage" score={c.character*4}/>
                                            <ScoreMin name="Coeur" score={c.heart*4}/>
                                        </div>
                                    </div>
                                    <h4 className='pl-2'>{c.username} le {c.updatedAt.split('T')[0].split('-').reverse().join('/')}</h4>
                                </div>    
                            )}
                            <p className='underline p-2 opacity-50 cursor-pointer' onClick={_ => setOpenScoreModal(true)}>Pour ajouter un commentaire cliquez ici</p>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    );
};

export default Anime