import React, { useState, useEffect, useRef } from 'react';
import FieldInput from '../components/FieldInput';
import FieldRadio from '../components/FieldRadio';
import { isNotMobile, api } from '../utils/functions';
import { useLocation, useNavigate } from 'react-router-dom';
import { useCookies } from 'react-cookie'
import axios from 'axios'
import Alerts from '../utils/alerts'

const Account = () => {

    const [mount, setMount] = useState(false)

    const [lUsername, setlUsername] = useState("")
    const [lPassword, setlPassword] = useState("")
    const [stayConnected, setStayConnected] = useState(true)
    const [resetEmail, setResetEmail] = useState("")
    const [rUsername, setrUsername] = useState("")
    const [rEmail, setrEmail] = useState("")
    const [rPassword, setrPassword] = useState("")
    const [rPassword2, setrPassword2] = useState("")
    const [rOtakuCode, setrOtakuCode] = useState("")

    const [maskPosition, setMaskPosition] = useState([[0,0,0], false])
    const [btnTitle, setBtnTitle] = useState("")
    const [forgot, setForgot] = useState(false)

    const location = useLocation().pathname

    let navigate = useNavigate()

    const [cookie, setCookie, removeCookie] = useCookies(['session-token'])

    const login = useRef(null)
    const register = useRef(null)

    const moveMask = () => {
        if(maskPosition[1]){
            setMaskPosition([[0, 0, login.current.getBoundingClientRect().height], false])
            setBtnTitle("J'ai déja un compte")
        }
        else{
            let mask = register.current.getBoundingClientRect()
            setMaskPosition([[mask.width, login.current.getBoundingClientRect().height, mask.height], true])
            setBtnTitle("Je n'ai pas de compte")
        }
    }

    useEffect(() => {
        if(!mount) {
            setMount(true)
            if(cookie['session-token']) axios.post(api + "/users/check", {token: cookie['session-token']}).then((res) => {if(res.data.correct){navigate('/animes', {replace: true})} else removeCookie('session-token')})
            if(location === "/" || location ===  "/register"){
                setMaskPosition([[0, 0, login.current.getBoundingClientRect().height], false])
                setBtnTitle("J'ai déja un compte")
            } else {
                let mask = register.current.getBoundingClientRect()
                setMaskPosition([[mask.width, login.current.getBoundingClientRect().height, mask.height], true])
                setBtnTitle("Je n'ai pas de compte")
            }
        }
        let portrait = window.matchMedia("(orientation: portrait)");
        const handleResize = () => {
            if(!maskPosition[1] && login.current){
                setMaskPosition([[0, 0, login.current.getBoundingClientRect().height], false])
            }
            else{
                let mask = register.current.getBoundingClientRect()
                setMaskPosition([[mask.width, login.current.getBoundingClientRect().height, mask.height], true])
            }
        }

        if(isNotMobile()) {
            window.addEventListener("resize", handleResize)
        }
        portrait.addEventListener("change", handleResize)
        return () => {window.removeEventListener("resize", handleResize)}
      }, [maskPosition, cookie, location, mount, navigate, removeCookie]);

    const handleSubmit = (e, from) => {
        e.preventDefault()
        switch(from){
            case "register":
                if(!/^(?=.*[a-z])(?=.*[A-Z])(?=.*[\d]).{8,20}$/.test(rPassword)) return Alerts(400, "Le mot de passe indiqué ne respecte pas les conditions")
                if(rPassword !== rPassword2) return Alerts(400, "Les deux mots de passe ne correspondent pas")

                axios.post(api + "/users/register", {
                    username: rUsername,
                    email: rEmail,
                    password: rPassword,
                    otaku: rOtakuCode
                })
                .then((res) => {
                    ['rUsername', 'rEmail', 'rPassword','rPassword2', 'rReferral'].forEach(name => document.getElementById(name).value = "")
                    setrUsername("")
                    setrEmail("")
                    setrPassword("")
                    setrPassword2("")
                    setrOtakuCode("")
                    setMaskPosition([[0, 0, login.current.getBoundingClientRect().height], false])
                    Alerts(res.status, res.data.success)
                })
                .catch((err) => {
                    Alerts(err.status, err.response.data.error)
                })
                break

            case "login": 
                axios.post(api + "/users/login", {
                    username: lUsername,
                    password: lPassword,
                    checked: stayConnected
                })
                .then((res) => {
                    Alerts(res.status, 'Vous êtes connecté')
                    setCookie('session-token', res.data.token, {path: '/', expires: new Date(res.data.expire * 1000)})
                    navigate('/animes', {replace: true})
                })
                .catch((res) => Alerts(res.status, res.response.data.error))
                break

            case "reset": 
                axios.post(api + "/users/resetReq", {email: resetEmail})
                .then((res) => {
                    Alerts(res.status, res.data.success)
                })
                .catch((res) => Alerts(res.status, res.response.data.error))
                break

            default :
                break
        }
    }

    return (
        <div className="w-full h-screen min-h-[850px] flex justify-center items-center">
            <div className='shadow-lg shadow-slate-500 tablet:flex tablet:w-[600px] tablet:h-[600px] phone:w-[340px]'>
                <div className='absolute z-10 duration-700 tablet:w-[300px] phone:w-[340px] bg-gradient-to-br from-bleen-100 to-green-400' style={isNotMobile() ? {height: maskPosition[0][2], marginLeft: maskPosition[0][0]} : {height: maskPosition[0][2], marginTop: maskPosition[0][1]}}>
                        <img src="/images/title.png" alt="Titre du site" className='mt-4 rounded-md tablet:w-[280px] tablet:mx-[10px] phone:w-[320px] phone:ml-[10px]' />
                        <p className="p-2 text-justify font-medium text-sm">
                            WatchAnime.V3 est toujours en cours de développement, des bugs sont donc encore possible. <br /><br />
                            Vos identifiants de connexions reste les mêmes <br /><br />
                            Belle vie à vous et bon visionnage :D
                        </p>
                        <button onClick={moveMask} className='border-white border-2 bg-bleen-300 p-2 rounded-full text-white font-medium tablet:w-[200px] tablet:mx-[50px] phone:w-[260px] phone:ml-[40px] cursor-pointer hover:bg-bleen-400 duration-300'>{btnTitle}</button>
                </div>
                <div className='bg-white mx-auto duration-1000 tablet:w-[300px] min-h-[330px]' id="login" ref={login} style={{opacity: Number(maskPosition[1])}}>
                    <div className='duration-700 absolute' style={!forgot ? {visibility: 'visible', opacity: 1} : {visibility: 'hidden', opacity: 0}}>
                        <h1 className='text-center text-2xl pt-6 mb-10'>CONNEXION</h1>
                        <form onSubmit={(e) => handleSubmit(e, "login")} >
                            <FieldInput name="username" placeholder="Pseudo" onChange={setlUsername}></FieldInput>
                            <FieldInput type="password" name="password" placeholder="Mot de passe" onChange={setlPassword}></FieldInput>
                            <label htmlFor="green-toggle" className="inline-flex relative items-center mr-5 cursor-pointer mb-3 phone:ml-[20px] tablet:ml-[10px] laptop:ml-[10px] desktop:ml-[20px]">
                                <input type="checkbox" value="" id="green-toggle" className="sr-only peer" checked={stayConnected} onChange={() => setStayConnected(!stayConnected)}/>
                                <div className="w-11 h-6 bg-gray-200 rounded-full peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-bleen-500"></div>
                                <span className="ml-3">Rester connecté ?</span>
                            </label>
                            <p className='phone:ml-[20px] tablet:ml-[10px] laptop:ml-[10px] desktop:ml-[20px] cursor-pointer' onClick={() => setForgot(true)}>Mot de passe oublié ?</p>
                            <input type="submit" value="SE CONNECTER" className='bg-white border border-bleen-400 p-2 mt-8 rounded-full tablet:w-[180px] tablet:mx-[60px] phone:w-[180px] phone:mx-[80px] cursor-pointer hover:bg-bleen-100 duration-300 mb-4'/>
                        </form>
                    </div>
                    <div className='duration-700 absolute' style={forgot ? {visibility: 'visible', opacity: 1} : {visibility: 'hidden', opacity: 0}}>
                        <h1 className='text-center text-2xl pt-6 mb-10'>MOT DE PASSE OUBLIÉ</h1>
                        <form onSubmit={(e) => handleSubmit(e, "reset")}>
                            <FieldInput name="email" placeholder="Ton e-mail" onChange={setResetEmail}></FieldInput>
                            <p className='phone:ml-[20px] tablet:ml-[10px] laptop:ml-[10px] desktop:ml-[20px] cursor-pointer' onClick={() => setForgot(false)}>Tu connais ton mot de passe ?</p>
                            <input type="submit" value="RÉINITIALISER" className='bg-white border border-bleen-400 p-2 mt-8 rounded-full tablet:w-[180px] tablet:mx-[60px] phone:w-[180px] phone:mx-[80px] cursor-pointer hover:bg-bleen-100 duration-300 mb-4' />
                        </form>
                    </div>
                </div>
                <div className='bg-white mx-auto duration-1000 tablet:w-[300px] tablet:mt-0' id="register" ref={register} style={{opacity: Number(!maskPosition[1])}}>
                    <h1 className='text-center text-2xl pt-6 mb-10'>INSCRIPTION</h1>
                    <form onSubmit={(e) => handleSubmit(e, "register")}>
                        <FieldInput name="rUsername" placeholder="Pseudo" onChange={setrUsername}></FieldInput>
                        <FieldInput type="email" name="rEmail" placeholder="E-mail" onChange={setrEmail}></FieldInput>
                        <FieldInput type="password" name="rPassword" placeholder="Mot de passe" onChange={setrPassword}></FieldInput>
                        <div className='p-1 pl-2 tablet:mb-5 tablet:-mt-4 phone:-mt-1 phone:mb-2 border border-bleen-400 rounded desktop:w-[260px] desktop:ml-[20px] tablet:w-[280px] tablet:ml-[10px] phone:w-[300px] phone:ml-[20px]'>
                            <FieldRadio condition={rPassword.length >= 8 && rPassword.length <= 20}>Entre&nbsp;<b>8 et 20 charactères</b></FieldRadio>
                            <FieldRadio condition={/[a-z]/.test(rPassword)}>Au moins&nbsp;<b>une minuscule</b></FieldRadio>
                            <FieldRadio condition={/[A-Z]/.test(rPassword)}>Au moins&nbsp;<b>une majuscule</b></FieldRadio>
                            <FieldRadio condition={/[\d]/.test(rPassword)}>Au moins&nbsp;<b>un chiffre</b></FieldRadio>
                        </div>
                        <FieldInput type="password" name="rPassword2" placeholder="Confirmation" onChange={setrPassword2}></FieldInput>
                        {rPassword2.length > 0 && <p className="p-1 pl-2 tablet:mb-5 tablet:-mt-4 phone:-mt-1 phone:mb-2 border border-bleen-400 rounded desktop:w-[260px] desktop:ml-[20px] tablet:w-[280px] tablet:ml-[10px] phone:w-[300px] phone:ml-[20px] text-sm" style={{color: rPassword === rPassword2 ? 'green' : 'red'}}>{rPassword === rPassword2 ? '✅ Les mots de passe sont identiques' : '❌ Les mots de passe sont différents'}</p>}
                        <FieldInput name="rReferral" placeholder="Code otaku" onChange={setrOtakuCode} maxlength={12}></FieldInput>
                        <input type="submit" value="S'INSCRIRE" className='bg-white border border-bleen-400 p-2 mt-8 rounded-full tablet:w-[180px] tablet:mx-[60px] phone:w-[180px] phone:mx-[80px] cursor-pointer hover:bg-bleen-100 duration-300 mb-4'/>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default Account ;