import { isNotMobile, api, ws, datas} from "../utils/functions";
import { useState, useRef, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import axios from "axios";
import { useLocation, useNavigate } from 'react-router-dom';

const roleToColor = {
    1 : 'red',
    2 : '#d295ff',
    3 : 'blue',
    4 : '#ffec00',
    5 : '#B0E8C3'
}

const Group = ({img = "", name, value, selected, onClick, className="", condition=false}) => {
    const [hover, setHover] = useState(false)
    return (
        <div className="w-4/5 relative">
            <div className={"overflow-hidden flex flex-col justify-center items-center text-center relative rounded-full outline-2 outline-bleen-700 "+className}style={{outlineStyle: selected===value ? 'solid' : 'none'}} onClick={_=> onClick(value, name)}>
                <img src={img} alt="groupe" className="w-full bg-white" />
                <div className="absolute top-0 bottom-0 right-0 left-0 flex text-center justify-center items-center bg-white/50 cursor-pointer duration-300 tablet:text-md phone:text-sm" style={{opacity: isNotMobile() ? (hover ? 100 : 0) : (value > 0 ? 100 : 0)}} onMouseOver={_ => setHover(true)} onMouseLeave={_ => setHover(false)}><p className="text-ellipsis overflow-hidden">{name}</p></div>
            </div>
            <span className="w-4 h-4 rounded-full bg-red-500 absolute z-10 top-0 right-0" style={{display: condition ? "block" : "none"}} />
        </div>
    )
}


const MessageSend = ({message}) => 
    <div className="py-2 w-4/5 float-right">
        <p className="p-2 float-right mx-2 bg-bleen-300 rounded-xl border-b-2 border-bleen-500 max-w-full break-words overflow-hidden">
            {message}
        </p>
    </div>


const MessageRecived = ({data, onClick, selected, updateFriend, friend}) => {
    const isFriend = Object.values(friend.friends).find(f => f.id === data.id) || Object.values(friend.attentes).find(f => f.id === data.id) || false
    return (
        <div className="py-2 w-4/5 max-w-4/5 float-left ml-2 relative">
            <div className="flex">
                <p className="p-2 float-left text-left bg-bleen-100 rounded-xl border-b-2 bre max-w-full break-words overflow-hidden" style={{borderColor: roleToColor[data.role]}}>
                    {data.message}
                </p>
            </div>
            <div className="text-sm opacity-50 flex">
                <p>
                    {data.time}&nbsp;-&nbsp;
                </p>
                <div>
                    <p className="underline cursor-pointer nocheck" onClick={_ => onClick(data.current)}>
                        {data.pseudo}
                    </p>
                    <div className='grid grid-cols-3 gap-2 -ml-8 z-100' style={{display: selected === data.current ? "grid" : "none"}}>
                        <div className="w-8 h-8 border-2 border-bleen-500 bg-bleen-300 rounded-full nocheck flex justify-center">
                            <img src={`/images/${ isFriend ? "del" : "add"}Friend.png`} alt={isFriend ? "del" : "add"} className="w-6 rounded-full cursor-pointer nocheck" onClick={_ => updateFriend(data.id)}/>
                        </div>
                        {/* <div className="w-8 h-8 bg-bleen-400 rounded-full nocheck"></div>
                        <div className="w-8 h-8 bg-bleen-400 rounded-full nocheck"></div> */}
                    </div>
                </div>
            </div>
        </div>
    );
} 


const ChatBox = ({vid=null, functions={}}) => {
    const [isOpen, setIsOpen] = useState(false)
    const [cookie] = useCookies(['session-token'])
    const [messages, setMessages] = useState([])
    const [number, setNumber] = useState(0)
    const [userList, setUserList] = useState([])
    const [userListVisible, setUserListVisible] = useState(false)

    const [groupPaddingTop, setGroupPaddingTop] = useState(0)
    const wave = useRef(null)
    const socket = useRef(null)
    const messagesBox = useRef(null)
    const [[id, role, pseudo, popup], setInfo] = useState([0,0,"",true])
    const [selectedForContext, setSelectedForContext] = useState(null)
    const [selectedDiscussion, setSelectedDiscussio] = useState(0)
    const [discussions, setDiscussions] = useState([])
    const [discussionName, setDiscussionName] = useState("Général")
    const [friend, setFriend] = useState({friends: {}, attentes: {}})
    const [users, setUsers] = useState([])
    const [searchUser, setSearchUser] = useState("")
    const [searchUserToAdd, setSearchUserToAdd] = useState("")
    const [listToAdd, setListToAdd] = useState([])
    const [groupName, setGroupName] = useState("")
    const [notification, setNotification] = useState(false)
    const [synchros, setSynchros] = useState([])
    const [mounted, setMounted] = useState(false)
    const [video, setVideo] = useState(null)
    const [refresh, setRefresh] = useState(false)
    const location = useLocation()

    const navigate = useNavigate()

    const typeToType = {
        e: "Épisode",
        f: "Film",
        o: "OAV",
    }

    useEffect(() => {
        window.addEventListener('resize', () => setGroupPaddingTop(wave.current?.height + 20))
        document.addEventListener('click', e => (e.target.className && typeof e.target.className.includes !== 'undefined' && !e.target.className.includes('nocheck')) && setSelectedForContext(null))
        document.addEventListener('click', e => (e.target.className && typeof e.target.className.includes !== 'undefined' && !e.target.className.includes('userList')) &&setUserListVisible(false))
    
        const interval = setInterval(_ => {
            if(selectedDiscussion > 0 && id > 0) {
                socket.current.send(JSON.stringify({notification: "interval", idDiscussion: selectedDiscussion, idUser: id}))
            }
            setNotification(discussions.map(d => d.isNotView).reduce((a, b) => a+b) > 0 || Object.values(friend.attentes).length > 0)
        }, 1000)


        return _ => clearInterval(interval)

    }, [wave, id, friend, selectedDiscussion, discussions])

    useEffect(_ => {
        socket.current = new WebSocket(ws)
        socket.current.onopen = _ => {
            socket.current.send(JSON.stringify({token: cookie["session-token"]}))
            console.log("Client try to connect...")
        }
        socket.current.onerror = e => console.log('fail', e)

        axios.get(`${api}/messages?idD=${0}`, {headers: {Autorization: cookie['session-token']}})
            .then(res => setMessages(res.data.messages))
        axios.get(`${api}/users/friend`, {headers: {Autorization: cookie["session-token"]}})
            .then(res => setFriend(res.data))
        axios.get(`${api}/discussions`, {headers: {Autorization: cookie["session-token"]}})
            .then(res => setDiscussions(res.data.discussions))
        axios.get(`${api}/messages/userList`, {headers: {Autorization: cookie["session-token"]}})
            .then(res => setUsers(res.data.users))
        
        return _ => socket.current.close()
    }, [cookie])

    useEffect(_ => {
        if(!socket.current) return;
        socket.current.onmessage = e => {
            const data = JSON.parse(e.data)

            const synchro = async(data) => {
                switch(data.action) {
                    case "list":
                        setSynchros(data.list)
                        if(!id)
                            return socket.current.send(JSON.stringify({synchro: {action: "list"}}))
                        const list = data.list.find(f => f.users.filter(g => g.id === id).length > 0)
                        if(!mounted) {
                            routeChange(data.list)
                            setMounted(true)
                            if(list && !list.users.find(f => f.id === id).role)
                                setRefresh(true)
                            socket.current.send(JSON.stringify({synchro: {action: "getactual", idDiscussion: selectedDiscussion}}))
                        }
                        if(list)
                            socket.current.send(JSON.stringify({synchro: {action: "rejoin", idDiscussion: list.idDiscussion, idUser: id, role:list.users.find(f => f.id === id).role}}))
                        break;
                    case "url":
                        const AnimeChange = data.url.split("/")[2] !== location.pathname.split("/")[2]
                        navigate(data.url, {replace: true})
                        if(functions?.loaded) {
                            if(AnimeChange) {
                                const d = await functions.reAnime(data.url.split("/")[2])
                                await functions.reActual(Number(data.url.split("/")[4]), data.url.split("/")[3], data.url.split("/")[2], d[0], d[1])
                            }
                            else
                                await functions.reActual(Number(data.url.split("/")[4]), data.url.split("/")[3], data.url.split("/")[2])
                            functions.setOnStream(true)
                        }
                        break;
                    case "actual":
                        setVideo(data.actual)
                        if(functions?.loaded)
                            functions.updateVideo(data.actual.data)
                        break;
                    default:
                        break;
                }
            }
                      

            if(data.synchro) 
                synchro(data.synchro)

            if(data.token){
                setInfo([data.token.id, data.token.role, data.token.pseudo, data.token.popup])
                setTimeout(() => {
                    if(messagesBox.current) 
                        messagesBox.current.scrollTop = messagesBox.current.scrollHeight
                }, 2000)
                socket.current.send(JSON.stringify({synchro: {action: "list"}}))
            }
            

            if(data.info)
                console.log(data.info)

            if(data.message) {
                setMessages(c => [...c, data])
                setTimeout(_ => {
                    messagesBox.current.scrollTop = messagesBox.current.scrollHeight
                    setNotification(discussions.map(d => d.isNotView).reduce((a, b) => a+b) > 0 || Object.values(friend.attentes).length > 0)
                    if(selectedDiscussion > 0 && id > 0) {
                        socket.current.send(JSON.stringify({notification: "update", idDiscussion: selectedDiscussion, idUser: id}))
                    }
                }, 300)
            }

            if(data.number) {
                setNumber(data.number)
                setUserList(data.userList)
            }

            if(data.friend)
                axios.get(`${api}/users/friend`, {headers: {Autorization: cookie["session-token"]}})
                    .then(res => setFriend(res.data))
            if(data.discussion) 
                axios.get(`${api}/discussions`, {headers: {Autorization: cookie["session-token"]}})
                    .then(res => {if(JSON.stringify(res.data.discussions) !== JSON.stringify(discussions)) setDiscussions(res.data.discussions)})
        }
    // eslint-disable-next-line
    }, [discussions, cookie, friend, id, selectedDiscussion, mounted, location, navigate, functions])

    useEffect(_ => {
        routeChange()
    // eslint-disable-next-line
    }, [location])

    useEffect(_ => {
        if(!socket.current) return;
        const actual = synchros.find(f => f.users.filter(g => g.id === id && g.role).length > 0)
        if(actual) {
            socket.current.send(JSON.stringify({synchro: {action: "video", idDiscussion: actual.idDiscussion, idUser:id, currentTime: vid.currentTime, play: vid.play}}))
            if(JSON.stringify(vid) !== JSON.stringify(video?.data)) {
                socket.current.send(JSON.stringify({synchro: {action: "actual", idDiscussion: actual.idDiscussion, idUser:id, actual: vid}}))
            }
            setVideo({idDiscussion:actual.idDiscussion, data:vid})
        }
    // eslint-disable-next-line
    }, [vid])

    const routeChange = (sync=synchros) => {
        if(!socket.current) return;
        const actual = sync.find(f => f.users.filter(g => g.id === id && g.role).length > 0)
        if(actual && /^\/anime\/[A-Za-z0-9∞!ä.]{2,75}\/[efo]\/\d{1,4}$/.test(location.pathname)) 
            socket.current.send(JSON.stringify({synchro: {action: "url", idDiscussion: actual.idDiscussion, url: location.pathname}}))
        else if(actual)
            socket.current.send(JSON.stringify({synchro: {action: "actual", idDiscussion: actual.idDiscussion, idUser:id, actual: {}}}))
    }

    const leaveOrJoin = _ => {
        if(synchros.filter(f => f.users.filter(g => g.id === id).length > 0).length > 0){
            socket.current.send(JSON.stringify({synchro: {action: "leave", idDiscussion: selectedDiscussion, idUser: id}}))
            if(/^\/anime\/[A-Za-z0-9∞!ä.]{2,75}/.test(location.pathname))
                functions.setOnStream(false)
        }
        else{
            socket.current.send(JSON.stringify({synchro: {action: "join", idDiscussion: selectedDiscussion, idUser: id}}))
            if(/^\/anime\/[A-Za-z0-9∞!ä.]{2,75}/.test(location.pathname))
                functions.setOnStream(true)
        }
    }

    const createSynchro = () => {
        if(((selectedDiscussion === 0 && id === 1) || selectedDiscussion > 0) && !synchros.includes(selectedDiscussion) && synchros.filter(f => f.users.filter(g => g.id === id).length > 0).length === 0) {
            socket.current.send(JSON.stringify({synchro: {action: "create", idDiscussion: selectedDiscussion, idUser: id, pseudo: pseudo, discussionName: discussionName}}))
            if(/^\/anime\/[A-Za-z0-9∞!ä.]{2,75}\/[efo]\/\d{1,4}$/.test(location.pathname))
                socket.current.send(JSON.stringify({synchro: {action: "url", idDiscussion: selectedDiscussion, url: location.pathname}}))
        }
    }

    const updateFriend = async(friend) => {
        await axios.post(`${api}/users/friend`, {idFriend: friend}, {headers: {Autorization: cookie['session-token']}})
        socket.current.send(JSON.stringify({friend: "update"}))
    }
    
    const responseFriend = async(resp, friend) => {
        await axios.post(`${api}/users/friend/res`, {idFriend: friend, resp: resp}, {headers: {Autorization: cookie['session-token']}})
        socket.current.send(JSON.stringify({friend: "update"}))
        setNotification(discussions.map(d => d.isNotView).reduce((a, b) => a+b) > 0 || Object.values(friend.attentes).length > 0)
    }

    const handleSubmit = e => {
        e.preventDefault()
        let message
        if(e.target.message)
            message = e.target.message.value.trim()
        else
            message = e.target.value.trim()
            
        if(message) {
            socket.current.send(JSON.stringify(id ? {message: message, id: id, role:role, pseudo:pseudo, idDiscussion: selectedDiscussion}: {message: message, idDiscussion: selectedDiscussion, token: cookie["session-token"]}))
            setMessages(c => [...c, {message: message, id:id, role:role, pseudo:pseudo, idDiscussion: selectedDiscussion}])
            setTimeout(() => {
                messagesBox.current.scrollTop = messagesBox.current.scrollHeight
            }, 50)
            if(e.target.message)e.target.message.value = ""
            else e.target.value = ""
        }
    }

    const goToDiscussion = async(idFriend, name) => {
        let discussionsId = discussions.filter(d => (d.users.includes(id) || d.users.includes(idFriend)) && d.nb === 2).length > 0
        if(!discussionsId) {
            let {data} = await axios.post(`${api}/discussion/create`, {name: "", idFriend: [idFriend]}, {headers: {Autorization: cookie['session-token']}})
            discussionsId = data.idD
        }
        else {
            if(discussions.filter(d => d.users.includes(id) && d.nb === 2).length > 0)
                discussionsId = discussions.filter(d => d.users.includes(id) && d.nb === 2)[0].id
            else if (discussions.filter(d => d.users.includes(idFriend) && d.nb === 2).length > 0)
                discussionsId = discussions.filter(d => d.users.includes(idFriend) && d.nb === 2)[0].id
            else discussionsId = 0
        }
        socket.current.send(JSON.stringify({discussion: "update"}))

        setSelectedDiscussion(discussionsId, name)
    }

    const createGroup = async() => {
        let {data} = await axios.post(`${api}/discussion/create`, {name: groupName ,idFriend: listToAdd.map(m => m.id)}, {headers: {Autorization: cookie['session-token']}})
        socket.current.send(JSON.stringify({discussion: "update"}))
        setSelectedDiscussion(data.idB, groupName)
        setGroupName("")
    }

    const setSelectedDiscussion = async(idD, name) => {
        setSelectedDiscussio(idD)
        if(idD >= 0){
            await axios.get(`${api}/messages?idD=${idD}`, {headers: {Autorization: cookie['session-token']}})
                .then(res => setMessages(res.data.messages))
                socket.current.send(JSON.stringify({synchro: {action: "getactual", idDiscussion: idD}}))
        }       
        if(idD >= 1 && id > 0) socket.current.send(JSON.stringify({notification: "update", idDiscussion: idD, idUser: id}))
        setTimeout(_ => messagesBox.current.scrollTop = messagesBox.current.scrollHeight, 5)
        setDiscussionName(name)
    }

    const closePopup = async() => {
        await axios.post(`${api}/users/popup`, {}, {headers: {Autorization: cookie['session-token']}})
        setInfo([id, role, pseudo, true])
    }

    return (
        <>
            <div className="fixed rounded-tl-3xl right-0 tablet:m-5 phone:m-2 bg-white duration-300 tablet:bottom-0 phone:bottom-14 z-100 tablet:h-2/3 phone:h-fill tablet:max-w-sm max-w-adapt tablet:origin-[90%_90%] phone:origin-[16%_106%] overflow-hidden" style={{transform: `scale(${isOpen ? 1 : 0})`}}>
                <div onClick={_ => setIsOpen(c => !c)} className="fixed flex top-0 left-0 z-90 justify-center align-middle w-10 h-10 bg-bleen-200 hover:bg-bleen-100 duration-100 rounded-tl-3xl rounded-br-3xl cursor-pointer">
                    <img src="/images/svg/cross.svg" alt="chatbox" width="20"/>
                </div>
                <div className="relative z-50">
                    <img src="/images/wave.png" alt="wave" className="absolute rounded-tl-3xl" ref={wave} onLoad={e => setGroupPaddingTop(e.target.height + 20)} />
                    <div className="absolute z-10 left-10 top-8 flex ">
                        <span className="text-4xl">{number}</span>
                        <img src="/images/otaku.png" alt="otaku" className="h-10 userList cursor-pointer" onClick={_ => setUserListVisible(true)}/>
                        <div className="bg-white h-fit p-1 border border-bleen-400 userList" style={{display: userListVisible ? "block" : "none"}}>{userList.map(m => <p className="p-1 userList" key={Math.random()}>{m}</p>)}</div>
                    </div>
                    <div className="absolute z-10 left-1/2 top-1">
                        <span className="text-2xl">{discussionName}</span>
                    </div>
                    <div className="absolute z-10 phone:top-7 bphone:top-9 bbphone:top-12 tablet:top-9 right-3" style={{display: selectedDiscussion >= 0 ? "block" : "none"}}>
                        <img src={`/images/svg/cinema-${synchros.map(s => s.idDiscussion).includes(selectedDiscussion) ? (synchros.filter(f => f.idDiscussion === selectedDiscussion && f.users.filter(g => g.id === id).length > 0).length>0 ? 'leave' : 'on') : "off"}.png`} alt="cinema status" className=" phone:w-6 bphone:w-7 bbphone:w-8 tablet:w-6" onClick={createSynchro} style={{cursor: synchros.filter(f => f.idDiscussion === selectedDiscussion && f.users.filter(g => g.id === id).length > 0).length>0 || (selectedDiscussion === 0 && id !== 1) || (synchros.filter(f => f.users.filter(g => g.id === id).length > 0).length > 0) ? "default": "pointer"}}/>
                    </div>
                    <div className="absolute h-fit w-10/12 right-0 p-2" style={{top: wave?.current?.clientHeight+5+"px", display: synchros.map(s => s.idDiscussion).includes(selectedDiscussion) ? "block" : "none"}}>
                        <div className="w-full h-full bg-white shadow shadow-bleen-200 border border-bleen-200 rounded p-2 text-center">
                            <h6 className="font-bold flex w-full justify-center ">Session en cours ({synchros.find(f => f.idDiscussion === selectedDiscussion) && synchros.find(f => f.idDiscussion === selectedDiscussion).users.length} <img src="/images/otaku.png" alt="viewer" className="h-5"/>)</h6>
                            <div className="grid grid-cols-4" style={{gridTemplateRows: video?.data?.clean && video.idDiscussion === selectedDiscussion ? "repeat(4, minmax(0, 1fr))" : "repeat(2, minmax(0, 1fr))" }}>
                            {
                                video?.data?.clean && video.idDiscussion === selectedDiscussion ? <>
                                        <img src={`${datas}/${video.data.clean}/${video.data.clean}.png`} alt={video.data.anime} className="col-span-1 mt-2 row-span-3"/>
                                        <div className="col-span-3 flex p-2 row-span-3 justify-center">
                                            <p className="">{video.data.title} <br/> {!video.data.actual.seasonByArc && video.data.actual.saison ? `${video.data.actual.saison} - ` :  ''}[{typeToType[video.data.actual.type]} {video.data.actual.saison ? (video.data.actual.seasonByArc ? video.data.actual.numero : video.data.actual.numero-video.data.actual.debut+1 ) : video.data.actual.numero}] </p>
                                        </div>
                                    </>
                                :
                                    <p className="col-span-4 row-span-3">L'hôte n'as pas encore choisi la vidéo à regarder</p>
                            } 
                                <button className="col-span-4 row-span-1 bg-bleen-200 hover:bg-bleen-300" onClick={leaveOrJoin}>{synchros.filter(f => f.idDiscussion === selectedDiscussion && f.users.filter(g => g.id === id).length > 0).length > 0 ? "Quitter" : "Rejoindre"}</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex h-full w-full">
                    <div className="bg-bleen-300 h-full w-2/12 rounded-tl-3xl overflow-y-scroll scrollbar-hide flex flex-col items-center" style={{paddingTop: groupPaddingTop}}>
                        <Group img="/images/svg/general.svg" name="Général" value={0} selected={selectedDiscussion} onClick={setSelectedDiscussion}/>
                        <span className="border-t border-bleen-700 w-11/12 my-4"/>
                        <Group img="/images/svg/add.svg" name="Amis" value={-1} selected={selectedDiscussion} onClick={setSelectedDiscussion} condition={Object.values(friend.attentes).length > 0}/>
                        <span className="border-t border-bleen-700 w-11/12 my-4"/>
                        {discussions.map(d => <Group className="mb-4" key={Math.random()} img={`/images/svg/${d.nb > 2 ? "group" : "solo"}.svg`} name={d.name} value={d.id} selected={selectedDiscussion} onClick={setSelectedDiscussion} condition={d.isNotView}/>)}
                    </div>
                    <div className="h-full w-10/12 relative flex flex-col-reverse" style={{display: selectedDiscussion === -1 ? "none" : "flex"}}>
                        <form className="border-t border-bleen-300 bg-bleen-300 h-14 flex w-full" onSubmit={handleSubmit}>
                            <div className="h-14 border-b-2 border-bleen-300 rounded-3xl bg-white p-2 w-full">
                                <textarea 
                                    name="message"
                                    className="py-2 w-full resize-none h-10 outline-none" 
                                    placeholder="Votre message" 
                                    autoCapitalize="sentences" 
                                    maxLength="1500"
                                    spellCheck="false"
                                    onKeyUp={e => e.key === "Enter" ? handleSubmit(e) : ''}
                                />
                            </div>
                            <input type="image" src="/images/svg/send.svg" alt="SEND" className="w-14 p-4 hover:scale-125 duration-300"/>
                        </form>
                        <div className="w-full overflow-y-scroll scrollbar-hide pt-24" ref={messagesBox}>
                        {messages.filter(f => f.idDiscussion === selectedDiscussion).map(m => 
                            <div key={Math.random()} className="flow-root">
                                {m.actual === m.first ? <p className="w-full text-center opacity-50 ">{m.date}</p> : ''}
                                {m.id !== id ? <MessageRecived data={m} onClick={setSelectedForContext} selected={selectedForContext} updateFriend={updateFriend} friend={friend} /> : <MessageSend message={m.message}/>}
                            </div>
                        )}
                        </div>
                    </div>
                    <div className="h-full w-10/12 tablet:pt-24 phone:pt-36 justify-center overflow-y-scroll" style={{display: selectedDiscussion === -1 ? "grid" : "none"}}>
                        <div className="justify-self-center w-5/6 h-fit shadow-lg shadow-bleen-200 border-bleen-300 rounded-lg relative">
                            <div className="flex justify-between border-b border-bleen-200">
                                <input type="text" className="h-12 ml-2 rounded-lg focus:outline-none w-full" placeholder="Rechercher une personne" value={searchUser} onChange={e => setSearchUser(e.target.value)}/>
                                <img src="/images/svg/searchBarIcon.svg" alt="searchIcon" className="w-12"/>
                            </div>
                            <div className="absolute bg-white w-full z-100 shadow-lg shadow-bleen-200 border-bleen-300">
                                {users.filter(f => new RegExp(searchUser, 'i').test(f.username) && !Object.values(friend.friends).filter(g => g.validate === 1).map(m => m.id).includes(f.id) && searchUser.length >= 2).map(u => 
                                    <div className="flex mx-2 my-1" key={Math.random()}>
                                        <span>{u.username}</span>
                                        <img src={`/images/svg/${!Object.values(friend.friends).map(m => m.id).includes(u.id) ? "add" : "close"}.svg`} className="w-6 ml-auto cursor-pointer" alt="friend" onClick={_ => updateFriend(u.id) & setSearchUser("")}/>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="h-fit shadow-lg border-2 border-bleen-300 rounded-lg w-5/6 flex flex-col justify-between justify-self-center" style={{display: Object.values(friend.attentes).length > 0 ? "flex" : "none"}}>
                            <p className="p-2 w-full border-b border-bleen-100 justify-self-center">Demande en attente</p>
                            {Object.values(friend.attentes).map(f => 
                                <div key={Math.random()} className="w-full flex justify-between">
                                    <p className="ml-2 my-1">{f.username}</p>
                                    <div className="flex">
                                        <img src="/images/svg/check.svg" className="w-6 mr-2 cursor-pointer" alt="add" onClick={_ => responseFriend(true, f.id)}/>
                                        <img src="/images/svg/close.svg" className="w-6 mr-2 cursor-pointer" alt="delete" onClick={_ => responseFriend(false, f.id)}/>
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className="h-fit shadow-lg border-2 border-bleen-300 rounded-lg w-5/6 flex flex-col justify-between justify-self-center">
                            <div className="p-2 w-full border-b border-bleen-100 justify-self-center flex relative">
                                <p className="w-26">Créer un groupe</p>
                                <div className="flex justify-between border-b border-bleen-200">
                                    <input type="text" className="h-12 ml-2 rounded-lg focus:outline-none w-full" placeholder="2 amis ou +" value={searchUserToAdd} onChange={e => setSearchUserToAdd(e.target.value)}/>
                                    <img src="/images/svg/searchBarIcon.svg" alt="search" className="w-12"/>    
                                </div>
                                <div className="absolute right-0 top-16 bg-white w-full shadow-lg shadow-bleen-200 border-bleen-300">
                                    {users.filter(f => new RegExp(searchUserToAdd, 'i').test(f.username) && Object.values(friend.friends).filter(g => g.validate === 1).map(m => m.id).includes(f.id) && searchUserToAdd.length >= 1 && !listToAdd.includes(f)).map(u => 
                                        <div className="flex mx-2 my-1" key={Math.random()}>
                                            <span>{u.username}</span>
                                            <img src="/images/svg/add.svg" className="w-6 ml-auto cursor-pointer" onClick={_ => setListToAdd(c => [...c, u]) & setSearchUserToAdd("")} alt="add"/>
                                        </div>
                                    )}
                                </div>  
                            </div>
                            {listToAdd.map(m => 
                                <div key={Math.random()} className="w-full flex justify-between">
                                    <p className="ml-2 my-1">{m.username}</p>
                                    <div className="flex">
                                        <img src="/images/svg/close.svg" className="w-6 mr-2 cursor-pointer" onClick={_ => setListToAdd(c => c.filter(f => f.id !== m.id))} alt="delete"/>
                                    </div>
                                </div>
                            )}
                            <div className="border-t border-bleen-200 flex">
                                <input type="text" className="h-12 pl-2 rounded-lg focus:outline-none w-full" placeholder="Nom - 3 lettres minimum" value={groupName}  onChange={e => setGroupName(e.target.value)}/>
                                <button className="p-2 disabled:bg-gray-400 enabled:bg-bleen-200 enabled:hover:bg-bleen-300 enabled:cursor-pointer" disabled={(listToAdd.length < 2) || (groupName.trim().length < 3)} onClick={createGroup}>Créer</button>
                            </div>
                        </div>
                        <div className="h-fit shadow-lg border-2 border-bleen-300 rounded-lg w-5/6 flex flex-col justify-between justify-self-center">
                            <p className="p-2 w-full border-b border-bleen-100 justify-self-center">Mes amis</p>
                            {Object.values(friend.friends).filter(f => f.validate === 1).map(f => 
                                <div key={Math.random()} className="w-full flex justify-between">
                                    <p className="ml-2 my-1">{f.username}</p>
                                    <div className="flex">
                                        <span className="w-4 h-4 mt-2 mr-3 rounded-full" style={{backgroundColor: userList.includes(f.username) ? "lime" :  "red"}}/>
                                        <img src="/images/svg/send.svg" className="w-6 mr-2 cursor-pointer" alt="sendMessage" onClick={_ => goToDiscussion(f.id, f.name)}/>
                                        <img src="/images/svg/close.svg" className="w-6 mr-2 cursor-pointer" alt="delete" onClick={_ => responseFriend(false, f.id)}/>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <div onClick={_ => setIsOpen(c => !c)} className="flex relative justify-center align-middle duration-100 rounded-full cursor-pointer tablet:fixed tablet:bottom-5 tablet:right-5 tablet:z-90 tablet:w-20 tablet:h-20 phone:w-10 phone:h-10 tablet:bg-bleen-500 tablet:hover:bg-bleen-600 tablet:border-none phone:border-2 phone:border-white">
                <span className="w-4 h-4 rounded-full bg-red-500 absolute z-10 tablet:top-1 phone:-top-1 tablet:right-1 phone:-right-1"  style={{display: notification ? "block" : "none"}} />
                <img src="/images/svg/message.svg" alt="chatbox" width={isNotMobile() ? 40 : 24}  />
            </div>
            <div style={{display: popup ? "none" : "block"}} className="p-3 z-100 overflow-y-scroll max-h-fall tablet:-translate-x-1/2 phone:w-5/6 rounded-lg bg-white tablet:left-1/2 fixed -translate-y-1/2 top-1/2 shadow-lg border border-bleen-300 shadow-bleen-400 text-center">
                <p>
                    Yo la Miff ÉNORME MISE A JOUR /!\ <br/><br/> 
                    C'est une nouvelle fonctionnalité et c'est celle qui me rend le plus fier ! <br/> 
                    Vous pouvez maintenant créer des sessions de visionnage synchronisée entre membres de groupe ou entre amis.<br/> 
                    Tout ça est disponible via le tchat.<br/><br/>
                    Pour faire ça allez dans la discussion dans la quelle vous désirez créer une session et cliquer sur le petit logo de cinéma en haut a droite du chat. A partir de la vous serez l'hôte.<br/><br/>
                    Les autres membres pourront rejoindre la sessions a partir de la popup qui s'affichera dans la discussion.<br/><br/>
                    Quand vous êtes membre d'une session seule l'hôte garde le controle du site, pour reprendre le controle n'oubliez pas quitter la session via la popup dans la discussion.<br/><br/>
                    Si vous avez des questions ou que vous voyez un bug le salon Général est la pour ça !<br/><br/>
                    Bon visionnage !
                </p> 
                <br />
                <button className="p-2 rounded bg-bleen-200 hover:bg-bleen-300 enabled:cursor-pointer" onClick={closePopup}>Fermer</button>
            </div>
            <div style={{display: !refresh ? "none" : "block"}} className="p-3 z-100 overflow-y-scroll max-h-fall tablet:-translate-x-1/2 phone:w-5/6 rounded-lg bg-white tablet:left-1/2 fixed -translate-y-1/2 top-1/2 shadow-lg border border-bleen-300 shadow-bleen-400 text-center">
                <p>
                    Tu est dans une session de visionnage synchronisée, veux tu rester ou la quitter ?<br/><br/>
                </p> 
                <br />
                <div className="flex justify-around">
                    <button className="p-2 rounded bg-red-200 hover:bg-red-300 enabled:cursor-pointer" onClick={_ => setRefresh(false) & leaveOrJoin()}>Quitter</button>
                    <button className="p-2 rounded bg-bleen-200 hover:bg-bleen-300 enabled:cursor-pointer" onClick={_ => setRefresh(false)}>Rester</button>
                </div>
            </div>
        </>
    );
};

export default ChatBox;