import React from 'react';
import {Routes, Route, Navigate} from 'react-router-dom'
import Account from './pages/Account';
import Animes from './pages/Animes';
import Confirm from './pages/Confirm';
import Reset from './pages/Reset';
import Anime from './pages/Anime';
import Profile from './pages/Profile';
import Public from './pages/Public';
import Admin from './pages/admin/Admin';
import AAnime from './pages/admin/AAnime';
import AUser from './pages/admin/AUser';

const App = () => {
  return (
    <div className='w-full h-full relative text-bleen-800'>
      <div id="app" className='float-left'>
          <Routes>
            
            <Route path="/" element={ <Account />}/>
            <Route path="/register" element={ <Account />}/>
            <Route path="/login" element={ <Account />}/>
            <Route path="/reset" element={ <Reset />}/>
            <Route path="/profile" element={ <Profile />}/>
            <Route path="/public" element={ <Public />}/>
            <Route path="/confirm" element={ <Confirm />}/>
            <Route path="/animes" element={ <Animes />}/>
            <Route path="/anime/:title" element={ <Anime /> }/>
            <Route path="/anime/:title/:type/:number" element={ <Anime />}/>
            <Route path="/*" element={ <Navigate replace to="/animes"/>}/>

            <Route path="/admin" element={ <Admin />}/>
            <Route path="/admin/animes" element={ <Admin />}/>
            <Route path="/admin/animes/:title" element={ <AAnime/>}/>
            <Route path="/admin/users" element={ <Admin />}/>
            <Route path="/admin/users/:id" element={ <AUser/>}/>

          </Routes>
      </div>
    </div>
  );
};

export default App;