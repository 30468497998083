const NautiljonItem = ({children, onClick}) => {
    return (
        <div onClick={_ => onClick(children.url)} className="w-40 m-1 cursor-pointer text-center">
            <img src={children.imgUrl} alt="imageFromNautiljon" className="m-auto"/>
            <h3 className="font-bold" >{children.title}</h3>
            <h4>{children.type} - {children.year}</h4>
        </div>
    );
};

export default NautiljonItem;