import React, { useEffect } from 'react';
import axios from 'axios'
import { api } from '../utils/functions';

import { useSearchParams, useNavigate } from 'react-router-dom';
import Alerts from '../utils/alerts';

const Confirm = () => {

    const [searchParams, ] = useSearchParams()

    let navigate = useNavigate()

    useEffect(() => {
        axios.post(api + '/users/confirm', {id: searchParams.get('id'), time: searchParams.get('time')})
        .then((res) => {
            navigate('/login', {replace: true})
            Alerts(res.status, res.data.success)
        })
        .catch((err) => console.log(err));
    })
   
    return (
        <div>
            <p>COMPTE EN COURS DE VERIFICATION</p>
        </div>
    )
};

export default Confirm;