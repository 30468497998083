import React from 'react';
import { useState } from 'react';

const MSSelected = ({children, del}) => <p className='pl-2 p-1 bg-bleen-100 m-1 rounded-full flex'>{children.name}<img onClick={_ => del(children)} src="/images/svg/cross.svg" alt="cross" className='ml-1 cursor-pointer bg-bleen-200 hover:bg-bleen-300 rounded-full p-1 w-6'></img></p>
const MSButton = ({children, add}) => <button onClick={_ => add(children)} className='px-2 p-1 bg-bleen-100 m-1 rounded-full'>{children.name}</button>

const MultiSelector = ({data, selected, add, del, _new}) => {
    const [search, setSearch] = useState("");
    const [length, setLength] = useState(0)
    const sName = selected.map(v => v.name)
    const addAndClear = (id) => add(id) & setSearch('')
    
    return (
        <div className='border border-bleen-100'>
            <div className='flex'>
                <input type="text" placeholder='Rechercher' className='w-full pl-2 border-b border-bleen-100' value={search} onChange={e => setSearch(e.target.value) & setLength(Object.values(data).filter(f => new RegExp('^' + (e.target.value || '#'), 'i').test(f.name) && !sName.includes(f.name)).length)}/>
                { length === 0 && search !== "" &&
                    <button className="px-2 bg-bleen-200 hover:bg-bleen-300 cursor-pointer" onClick={_ => _new(search)}>Ajouter</button>
                }
            </div>
            <div className="w-full flex flex-wrap border-b border-bleen-100">{selected.map(v => <MSSelected del={del} key={v.id}>{v}</MSSelected>)}</div>
            <div className="w-full flex flex-wrap">{Object.values(data).filter(f => new RegExp('^' + (search || '#'), 'i').test(f.name) && !sName.includes(f.name)).map(v => <MSButton add={addAndClear} key={v.id}>{v}</MSButton>)}</div>
        </div>
    );
};

export default MultiSelector;