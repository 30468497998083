import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie'
import { NavLink, useNavigate } from 'react-router-dom';
import { isNotMobile } from '../utils/functions';
import SearchBar from './SearchBar';
import ChatBox from './ChatBox';

const NavButton = ({children, className, onClick=_ => {}}) => {
    return <button className={"tablet:h-full tablet:px-4 tablet:w-auto phone:w-full lining-nums phone:p-4 tablet:p-0 tablet:text-sm laptop:text-base phone:mt-1 tablet:mt-0 bg-bleen-200 hover:bg-bleen-400 duration-300 " + className} onClick={onClick}>{children}</button>
};

const Nav = ({children, move=false, video=null, functions={}}) => {
    const [onDesktop, setOnDesktop] = useState(isNotMobile())
    const [open, setOpen] = useState(false)
    const [searchOpen, setSearchOpen] = useState(false)
    const [,, removeCookie] = useCookies(['session-token'])
    const pathname = window.location.pathname

    let navigate = useNavigate()

    useEffect(() => {
        window.addEventListener("resize", () => setOnDesktop(isNotMobile()))
        document.addEventListener('click', e => {
            if(e.target) {
                if(!/(i|s|a|d|g|b)Search/.test(e.target.className)) {
                    setSearchOpen(false)
                }
            }
        })
    })
    
    if(onDesktop)
        return (
            <>
                <ChatBox vid={video} functions={functions}/>
                <div className="w-full h-14 fixed tablet:top-0 phone:bottom-0 bg-white z-50 flex">
                    <div className="w-3/5 flex flex-row">
                        <NavLink to="/animes" className="cursor-pointer mr-0.5"><img src="/images/title.png" alt="title" className='h-14'/></NavLink>
                        <NavLink to="/animes" className="cursor-pointer h-full"><NavButton className="mr-0.5">Accueil</NavButton></NavLink>
                        {children}
                    </div>
                    <div className="w-2/5 flex flex-row-reverse">
                        {
                            pathname !== '/animes'
                            ? <div onClick={() => navigate(-1)} className="cursor-pointer h-full"><NavButton className="ml-0.5">Retour</NavButton></div>
                            : <NavLink to="/profile" className="cursor-pointer h-full"><NavButton className="ml-0.5">Profil</NavButton></NavLink>
                        }
                        <SearchBar move={move}/>
                    </div>
                </div>
            </>
        );
    else
        return (
            <>
                {searchOpen && <>
                    <SearchBar move={move}/>
                    <div className="relative h-14 top-0"/>
                </>}
                <div className='fixed h-full pb-14 w-full z-40 duration-700 flex' style={{left: open ? '0%' : '100%'}}>
                    <div className="h-full w-1/4 relative" onClick={_ => setOpen(false)}/>
                    <div className="bg-bleen-300 h-full w-3/4" style={{boxShadow: '-10px 0px 20px 8px #0000005A'}}>
                        <div className="flex flex-col justify-between h-full">
                            <div>
                                <img src="/images/title.png" alt='Watch Anime'/>
                                {children}
                            </div>
                            <div>
                                <NavLink to="/login" onClick={() => removeCookie('session-token')} className="cursor-pointer h-full bottom-14"><NavButton className="mr-0.5">Déconnexion</NavButton></NavLink>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="w-full h-14 fixed bottom-0 bg-bleen-300 z-50 flex items-center justify-evenly">
                    <ChatBox vid={video} functions={functions}/>
                    {
                        pathname !== '/animes'
                        ? <div onClick={() => navigate(-1)} className="cursor-pointer w-10 h-fit"><img src="/images/svg/return.svg" alt="return"/></div>
                        : <NavLink to="/profile" className="cursor-pointer w-10 h-fit"><img src="/images/svg/account.svg" alt="account"/></NavLink>
                    }
                    {
                        pathname !== '/animes'
                        ? <NavLink to="/animes" className="cursor-pointer w-10 h-fit"><img src="/images/svg/home.svg" alt="home"/></NavLink>
                        : <div className="cursor-pointer w-10 h-fit bSearch" onClick={_ =>  setSearchOpen(!searchOpen)}><img src="/images/svg/search.svg" className="iSearch" alt="search"/></div>
                    }
                    <div className="cursor-pointer w-10 h-fit" onClick={() => setOpen(!open)}><img src="/images/svg/menu.svg" alt="menu"/></div>
                </div>
            </>
        );  
};

export {
    Nav,
    NavButton
};