import React from 'react';
import { Nav } from '../components/Nav';

const Public = () => {
    return (
        <div>
            <Nav></Nav>
        </div>
    );
};

export default Public;