import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import AnimePanel from '../components/AnimePanel';
import { useCookies } from 'react-cookie'
import { api } from '../utils/functions';
import { useNavigate, NavLink } from 'react-router-dom';
import {Nav, NavButton} from '../components/Nav';
import Carousel from '../components/Carousel';
import SliderAnime from '../components/SliderAnime';
import Alerts from '../utils/alerts.js'


const Animes = () => {
    const [animes, setAnimes] = useState({})
    const [history, setHistory] = useState({})
    const [favorites, setFavorites] = useState({})
    const [latest, setLatest] = useState({})
    const [inProgress, setInProgress] = useState({})
    const [filter, setFilter] = useState("all")
    const [letter, setLetter] = useState("")
    const [filterType, setFilterType] = useState(false)
    const [otk, setOTK] = useState("")
    const [mount, setMount] = useState(false)
    const [token, setToken] = useState({})
    const [cookie,, removeCookie] = useCookies(['session-token'])
    const [multiplicator, setMultiplicator] = useState(1)
    let navigate = useNavigate()
    const up = useRef(null)

    const typeToVal = {
        e: 'nbEp',
        f: 'nbFl',
        o: 'nbOa'
    }

    useEffect(() => {
        if(!mount) {
            setMount(true)
            if(cookie['session-token']) 
                axios.post(api + "/users/check", {token: cookie['session-token']})
                .then((res) => {
                    if(!res.data.correct){
                        navigate('/login', {replace: true})
                        removeCookie('session-token')
                    }
                    else {
                        setToken(res.data.correct)
                        setOTK(res.data.otaku)
                    }
                })
            else
                navigate('/login', {replace: true})
                
            axios.get(api + '/animes/list?t=max', {headers: {Autorization: cookie['session-token']}})
            .then((res) => {
                setAnimes(res.data.animes)
                let tempProgress = []
                let i = 0
                for(const [, value] of Object.entries(res.data.animes)){
                    if(value.idState > 1 && (value.nbEp > 0 || value.nbFl > 0)) {
                        tempProgress[i] = value
                        i++
                    }
                }
                let progress = tempProgress
                    .map(value => ({ value: value, sort: Math.random() }))
                    .sort((a, b) => a.sort - b.sort)
                    .map(({ value }) => value)
                setInProgress(progress)
            })

            axios.get(`${api}/users/histories`, {headers: {Autorization: cookie['session-token']}})
                .then(res => setHistory(res.data.history))
                
            axios.get(`${api}/users/favorites`, {headers: {Autorization: cookie['session-token']}})
                .then(res => setFavorites(res.data.favorites))

            axios.get(`${api}/latest/list`)
                .then(res => setLatest(res.data.latest))
        }
    }, [mount, cookie, navigate, removeCookie])

    return (
        <div> 
            <Nav>
                { token.role <= 3 && <NavLink to="/admin/animes" className="cursor-pointer h-full"><NavButton className="mr-0.5">Administration</NavButton></NavLink>}
                <NavButton onClick={e => navigator.clipboard.writeText(e.target.textContent) & Alerts(200, "Votre Code OTAKU à été copié dans le presse papier")}>{otk}</NavButton>
            </Nav>
            <main>
                {inProgress[0] && <Carousel>{inProgress}</Carousel>}
                
                <SliderAnime 
                    titre="Les dernières publications"
                    latest={true}
                    filter={_ => true}
                    data={latest}
                />

                {Object.values(history).filter(value => value.type === "e" && value.nbEp - value.number === 1).length > 0 &&
                    <SliderAnime  
                        titre="Plus qu'un épisode"
                        filter={value => value.type === "e" && value.nbEp - value.number === 1} 
                        history={true}
                        data={history}
                    />}

                {Object.values(history).filter(value => value[typeToVal[value.type]] > value.number && (value.type === "e" ? value.nbEp - value.number > 1 : true)).length > 0 &&
                    <SliderAnime  
                        titre="Reprendre la lecture"
                        filter={value => value[typeToVal[value.type]] > value.number && (value.type === "e" ? value.nbEp - value.number > 1 : true)} 
                        history={true}
                        data={history}
                    />}

                {Object.values(favorites).length > 0 &&
                    <SliderAnime 
                        titre="Mes favoris" 
                        filter={_ => true}
                        data={favorites}
                    />}

                <div className="tablet:pb-4 phone:pb-16 mt-10">
                    <h1 className='text-center text-2xl' ref={up}>Liste des {Object.values(animes).filter(f => f.nbEp > 0 || f.nbFl > 0).length} animés </h1>
                    <div className='w-full flex justify-center '>
                        <button onClick={_ => setFilter("all") & setMultiplicator(1)} className="p-2 border border-bleen-500 bg-bleen-100 rounded-full hover:bg-bleen-200" style={{backgroundColor: filter === "all" ? "rgb(172,231,197)" : ""}}>Tout</button>
                        <button onClick={_ => setFilter("series") & setMultiplicator(1)} className="p-2 border border-bleen-500 bg-bleen-100 rounded-full hover:bg-bleen-200 mx-4" style={{backgroundColor: filter === "series" ? "rgb(172,231,197)" : ""}}>Séries</button>
                        <button onClick={_ => setFilter("movies") & setMultiplicator(1)} className="p-2 border border-bleen-500 bg-bleen-100 rounded-full hover:bg-bleen-200" style={{backgroundColor: filter === "movies" ? "rgb(172,231,197)" : ""}}>Films</button>
                    </div>
                    <div className='w-full flex justify-evenly mt-2'>
                        {"9ABCDEFGHIJKLMNOPQRSTUVWY".split('').map(v => <button key={Math.random()} className='hover:text-bleen-600 p-0.5' onClick={e => setLetter(e.target.innerText) & setFilterType(true)}>{v}</button>)}
                    </div>
                    <div className="flex flex-wrap justify-center items-center">
                        {Object.values(animes)
                            .filter(f => filter === "all" ? f.nbEp > 0 || f.nbFl > 0 : (filter === "series" ? f.nbEp > 0 : f.nbFl > 0))
                            .filter((f, i) => filterType 
                                ? f.title.split('')[0] === letter
                                : i >= 0 + ((multiplicator-1) * 20) && i <= 19 + ((multiplicator-1) * 20)
                            )
                            .map((value) => <AnimePanel key={Math.random()} className={["relative", "m-2"]}>{value}</AnimePanel>)}
                    </div>
                    <div className="flex flex-wrap justify-center items-center">
                        {Array
                            .apply(null, {length: Math.ceil(
                                Object.values(animes)
                                    .filter(f => filter === "all" ? f.nbEp > 0 || f.nbFl > 0 : (filter === "series" ? f.nbEp > 0 : f.nbFl > 0))
                                    .length/20
                                )
                            })
                            .map(Number.call, Number)
                            .map(i => <p key={Math.random()} className="p-2 cursor-pointer rounded-full border border-bleen-300 mx-1" onClick={e => setMultiplicator(parseInt(e.target.innerText)) & setFilterType(false) & window.scrollTo(0,up.current.offsetTop-25)} style={{backgroundColor: i+1===multiplicator ? 'rgb(172, 231, 197)':'rgb(204, 240, 219)'}}>{i+1}</p>)}
                    </div>
                </div>
            </main>
        </div>
    );
};

export default Animes;