import {useState} from 'react';
import {CSSTransition} from 'react-transition-group';

const FlipCard = ({children}) => {
    const [showFront, setShowFront] = useState(true)
    return (
        <CSSTransition in={showFront} classNames="flip" timeout={1000}>
            <div className="w-full h-full relative cursor-pointer" style={{transformStyle: "preserve-3d"}} onClick={_ => setShowFront(c => !c)}>
                {children}
            </div>
        </CSSTransition>
    );
};

export default FlipCard;