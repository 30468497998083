import React, { useEffect, useRef, useState } from 'react';

const FieldText = ({children}) => {

    const p = useRef(null)
    const [text, setText] = useState("")
    const [isLong, setIsLong] = useState(false)
    const [isMax, setIsMax] = useState(false)
    const [isMount, setIsMount] = useState(false)
    
    useEffect(() => {
        if(!isMount && children !== undefined) {
            if(children.length > 300){
                setText(children.slice(0, 300) + '.....')
                setIsLong(true)
            }
            else {
                setText(children)
            }
            setIsMount(true)
        }
    }, [isMount, children])

    const handleClick = () => {
        if(isMax) {
            setText(children.slice(0, 300) + '.....')
            setIsMax(false)
        }
        else {
            setText(children)
            setIsMax(true)
        }
    }

    return <div className="leading-6"><p ref={p}>{text}</p>{isLong && <p className='text-center font-medium text-bleen-600 leading-4 cursor-pointer' onClick={handleClick}>{isMax && '▲'}<br />Voir {isMax ? 'moins' : 'plus'}<br />{!isMax && '▼'}</p>}</div>
};

export default FieldText;