import React from 'react';
import { useEffect, useState, useRef } from 'react';
import { useCookies } from 'react-cookie';
import axios from 'axios';
import { api } from '../utils/functions';
import SearchAnime from './SearchAnime';

const SearchBar = ({move}) => {
    const [animes, setAnimes] = useState({})
    const [mount, setMount] = useState(false)
    const [search, setSearch] = useState("")
    const [focus, setFocus] = useState(false)
    const [cookie] = useCookies(['session-token'])
    const sBar = useRef(null)

    useEffect(() => {
        if(!mount) {
            setMount(true)
            axios.get(api + '/animes/list?t=min', {headers: {Autorization: cookie['session-token']}})
            .then((res) => {
                setAnimes(res.data.animes)
            })
        }
        document.addEventListener('click', e => {
            if(e.target && !/(s|a|d)Search/.test(e.target.className))
                setFocus(false)
        })
    }, [mount, cookie])

    return (
        <div className="phone:fixed tablet:relative gSearch z-50 phone:w-full tablet:w-auto">
            <div className='h-full bg-bleen-200 p-2 flex'>
                <img src="/images/svg/searchBarIcon.svg" alt="search icons" className='h-10 mr-2' />
                <input placeholder='Rechercher' onFocus={_ => setFocus(true)} type="search" className="p-2 h-full bg-bleen-200 w-full sSearch" value={search} onChange={e => setSearch(e.target.value)} ref={sBar}/>
            </div>
            <div className="bg-white absolute w-full pl-1 max-h-96 overflow-y-scroll scroll-smooth z-50 scrollbar-hide dSearch" hidden={!focus}>
                {Object.values(animes).filter(f => f.nbEp > 0 || f.nbFl > 0).filter(a => new RegExp(search !== "" ? search.replace(/ |:|-|\//g, '') : "###", 'i').test(`${a.cleanTitle}${a.aliases.replace(/ |,|:|\//g, '')}`)).map(a => <SearchAnime setSearch={setSearch} move={move} anime={a} key={Math.random()} className="aSearch"/>)}
            </div>
        </div>
    );
};

export default SearchBar;