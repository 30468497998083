const Alerts = (status, message) => {
    if(document.getElementById('alert') === null){
        let alert = document.createElement('div');
        alert.innerHTML = /*html*/`
            <p class="bg-white p-2 px-4 rounded" style="color: ${status === 200 ? 'green' : 'red'};">
                ${message}
            </p>
        `;

        alert.style.cssText = `
            position: fixed;
            top: -100px;
            right: 18px;
            z-index: 1000;
            transition-duration: 2s;
        `
        alert.id = "alert"
        document.body.appendChild(alert);
        setTimeout(() => {
            alert.style.top = '100px'
            setTimeout(() => {
                alert.style.right = '-620px'
                alert.style.top = '-25px'
                setTimeout(() => {
                    alert.remove()
                }, 500)
            }, 5000)
        },1)
    }
}

export default Alerts