import React from 'react';
import { NavLink } from 'react-router-dom';

const Video = ({type, video, saison={}, actual, onStream, anime, reActual}) => {
    
    const fORo = {f:"film", o:"oav"}
    const handleClick  = e => {
        if(onStream) e.preventDefault()
        else reActual(video.number, type)
    }
    return (
        <NavLink onClick={handleClick} to={`/anime/${anime.cleanTitle}/${type}/${video.number}`} key={video.number} className="bg-white pl-2 leading-6 truncate" style={{color: actual.numero === video.number && actual.type === type ? 'rgb(52,178,104)' : '', cursor: onStream ? "default" : "pointer"}}>{
            type === "e" ?
                `${anime.seasonByArc ? video.number : (saison.start ? (video.number-saison.start+1) : video.number)} - ${video.title !== " " ? video.title : `Épisode ${anime.seasonByArc ? video.number : saison.start ? (video.number-saison.start+1) : video.number}`}`
            :
            `${video.title || `Voir le ${fORo[type]}`}`
        }</NavLink>
    )
}


export default Video;